import axios from "axios";
import store from "../store";

let headers = {
  Authorization: `Bearer ${store.getters.token || localStorage.getItem('token')}`,
};

export const signInHandler = async (obj) => {
  let res = await axios.post("/users/sign-in", obj);

  return res;
};

export const signUpHandler = async (obj) => {
  let res = await axios.post("/users/sign-up/email-address", obj);

  return res;
};

export const checkEmail = async (email) => {
  let res = await axios.head(`/users?email=${email}`);

  return res;
};

export const resetPassword = async (obj) => {

  let res = await axios.post("/users/reset-password", obj);

  return res;
};

export const updateUserDetails = async (body) => {
  let res = await axios.patch("/users/details", body, {
    headers,
  });

  return res;
};

export const updateUserEmail = async (body) => {
  let res = await axios.patch("/users/details/email-address", body, {
    headers,
  });

  return res;
};

export const sendEmailConfirmation = async () => {
  let res = await axios.post(
    "/users/send-email-confirmation-token",
    {},
    {
      headers,
    }
  );

  return res;
};

export const confirmEmailHandler = async (token) => {
  let res = await axios.post("/users/confirm-email-address", {
    token,
  });

  return res;
};


export const sendPhoneConfirmationToVerify = async () => {
  let res = await axios.post("/users/send-phone-number-confirmation-token", {
    languageCode: localStorage.getItem('language')
  }, {
    headers
  });

  return res;
};

export const sendPhoneConfirmationToUpdate = async (obj) => {
  let res = await axios.post("/users/confirm-phone-number", obj, {
    headers,
  });

  return res;
};

export const sendPhoneChangeCode = async (obj) => {
  let res = await axios.post("/users/send-phone-number-change-token", obj, {
    headers,
  });

  return res;
};

export const changePhoneNumber = async (obj) => {
  let res = await axios.post("/users/change-phone-number", obj, {
    headers,
  });

  return res;
};