
export default {

    emailValidRules: [
        // (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    emailRules: [
        (v) => !!v || "rules.emailRequired",
        (v) => /.+@.+\..+/.test(v) || "rules.emailValid",
      ],
    sizeRules: [
        // files => !files || !files.some(file => file.size > 2_097_152) || 'size should be less than 2 MB!'
    ],
}