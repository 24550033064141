export default {
  experienceDetails: {
    privacyPolicy:
      "قرأت وأوافق على <span class='font-600'>سياسة الخصوصية</span> ",
  },
  homepage: {
    searchTitle: "قم بإيجاد المكان الذي تريد زيارته",
    world: 'العالم',
    kingdom: 'المملكة'
  },
  rules: {
    checkAllFields: "يرجى التحقق وتصحيح جميع الحقول مع تحذير",
    mustTrue: "يجب الموافقة على الشروط",
  },
  auth: {
    length: "يجب أن تكون على الأقل 6 عناصر",
    containUppercase: "يجب أن تحتوي على الأقل على حرف واحد كبير",
    containLowercase: "يجب أن تحتوي على الأقل على حرف واحد صغير",
    oneSymbol: "يجب أن تحتوي على الأقل على رمز خاص",
    containNumbers: "يجب أن تحتوي على الأقل علي رقم واحد",
  },
  blogs: {
    bookYourTrip: "احجز رحلتك",
  },

  filter: {
    priceInterval: "تحديد السعر",
    selectPrice: "تحديد السعر",
    selectType: "تحديد النوع",
    selectDifficulty: "تحديد الصعوبة",
    selectCountryCode: 'اختيار البلد',
    sortByPrice: 'ترتيب حسب السعر'

  },

  footer: {
    copyRight1: "بونداي © ",
    copyRight2: "  جميع الحقوق محفوظة",
    registerNumber: "الرقم الضريبي #",
    eCR: "رقم التسجيل التجاري #",
    ksa: "رقم ترخيص وزارة السياحة السعودية",
  },

  messages: {
    newExperienceAdded: "New experience added succesfully",
    subjectForm: "تم الارسال بنجاح",
    wrong: "خطأ, يرجى المحاولة لاحقا!",
  },
  global: {
    english: "English",
    arabic: "العربية",
    chinese: "中国人",
    day: "يوم",
    bondaiGroup: "مجموعات بونداي",
    reviews: "تقييم",
    save: "تخفيض",
    activityDetails: "تفاصيل النشاط",
    perPerson: "للشخص الواحد",
    from: "ابتدائا من",
    cookiesTitle: "نحن نقيم مستوى الخصوصية لديك",
    cookiesSubTitle: `
    نحن نستخدم ملفات تعريف الارتباط لتحسين تجربة التصفح الخاصة بك ، وخدمة الإعلانات أو المحتوى المخصص ، وتحليل حركة المرور لدينا. بالنقر فوق "أنا أفهم" ، فإنك توافق على استخدامنا لملفات تعريف الارتباط.
    `,
    understand: "أفهم ذلك",
    readMore: "قراءة المزيد",
    homepage: 'الصفحة الرئيسية',
    experiences: 'الرحلات',  
    age: 'سنة',
    please: 'يرجى',
    clickHere: 'النقر هنا',
    toSendVerify: 'من أجل إرسال رسالة تفعيل الحساب',
    gender: 'الجنس',
    title: 'اللقب',
    January: "كانون الثاني",
    February: "شباط",
    March: "اّذار",
    April: "نسيان",
    May: "أيّار",
    June: "حزيران",
    July: "تموز",
    August: "اّب",
    September: "أيلول",
    October: "تشرين الأول",
    November: "تشرين الثاني",
    December: "كانون الأول",    
    very: "سهل جدا",
    easy: "سهل",
    moderate: "معتدل",
    challenging: "تحدي",
    extreme: "أقصى صعوبة",    
  },

  dashboard:{
    changePlan: 'تغيير الخطة',
    conventionCancelled: 'تم إلغاء الاتفاقية',
    duplicateBooking: 'حجز مكرر',
    flightCancelled: 'تم إلغاء الرحلة',
    changeBusiness: 'غيير مكان أو تاريخ حدث الأعمال',
    deathFamily: 'حالة وفاة في العائلة',
    other: 'غير ذلك',
    enterCode: 'الرجاء إدخال الرمز المكون من 6 أرقام المرسل إليه',
    phoneVerification: 'التحقق من رقم الهاتف',
    resendCode: 'أعد إرسال الرمز',
    updatePhone: 'تحديث الهاتف',
    verifyPhone: 'التحقق  ',
    newPhoneNumber: 'رقم الهاتف الجديد',
    verified: 'موثوق'


  },
  cancellation:{
    fullPart1: 'إلغاء مجاني: يمكنك إلغاء حجزك مجانًا حتى',
    fullPart2: 'سيتم رد كامل المبلغ الذي دفعته إلى طريقة الدفع الخاصة بك.',

    halfPart1: 'إلغاء العقوبة الجزئية: وفقًا لسياسة الإلغاء، ',
    halfPart2: 'سيتم فرض الغرامة من المبلغ الذي دفعته مقابل حجزك. المتبقي',
    halfPart3: 'سيتم ردها إلى طريقة الدفع الخاصة بك.',

    none: 'غير قابلة للاسترداد: إذا قمت بإلغاء حجزك، فلن يتم استرداد أي مبالغ وفقًا لسياسة الإلغاء.',
    customReason: 'يرجى كتابة السبب'
  },

  
  static: {
    termsTitle: "سياسة الشروط",
    terms: `
    <p><strong>اتفاقية الاستخدام</strong></p>
<p><br></p>
<p>بونداي ترحب بكم! تُشكل اتفاقية الاستخدام هذه بجانب سياسة الخصوصية الشروط والأحكام المنظمة لاستخدامكم موقع/تطبيق بونداي <a data-fr-linked="true" href="//www.bondai.io">www.bondai.io</a> وكافة الآثار القانونية التي تنتج عن استخدامكم للخدمات المقدّمة، حيث أنّ أي استخدام لخدمات موقع/تطبيق بونداي يُعد موافقة وقبول لكافة شروط وأحكام هذه الاتفاقية لذلك تعتبر هذه الاتفاقية سارية المفعول ونافذة بمجرد قيامكم بالتسجيل أو استخدام موقع/تطبيق بونداي. قد نقوم بتغيير شروط وأحكام هذه الاتفاقية من وقت لآخر لذلك ننصحك بمراجعة الاتفاقية هذه بشكل دوري لمعرفة التغيرات التي تطرأ عليها.</p>
<p><br></p>
<p>استخدامك لموقع/تطبيق بونداي مشروط بقبولك لشروط وأحكام اتفاقية الاستخدام هذه، فإذا كنت لا توافق على أي جزء من الاتفاقية فيجب عليك التوقف عن استخدام الموقع/التطبيق وحذف حسابك تمامًا. حيث أنّ استمرارك لاستخدام الموقع/التطبيق هو إقرار منك على أنّك قد قرأت وفهمت أحكام وشروط هذه الاتفاقية وقبلتها. وفي حال كان هناك أي جزء غير مفهوم من هذه الاتفاقية، أو أي خدمة مقدّمة على موقع/تطبيق بونداي يرجى التواصل معنا.</p>
<p><br></p>
<p><strong>التعريفات</strong></p>
<p><strong>منصة/نحن/بونداي</strong>: يقصد بهذه العبارة شركة بونداي العربية للسفر والسياحة سجل تجاري (4030480178) ويشمل هذا التعريف كافة أشكال الشركة، سواء كانت موقع أو تطبيق إلكتروني.</p>
<p><strong>المستخدم/العميل/أنت</strong>: يقصد بهذه العبارة الشخص الذي يستخدم المنصة أو يقوم بزيارتها أو التسجيل فيها أو طلب أي خدمة من خلالها، والذي يجب أن يلتزم بشروط وأحكام هذه الاتفاقية، كما يجب ملاحظة أنّ كلمة &quot;أنت&quot; قد تشير إلى أكثر من شخص.</p>
<p><strong>مزود الخدمة</strong>: يقصد بهذه العبارة الشركة أو المؤسسة أو الأفراد أو الشركات التابعة والذين يقومون بتقديم الخدمات عبر المنصة، سواء كانوا متعاقدين مع المنصة أو غير متعاقدين.</p>
<p><strong>الخدمة/الخدمات</strong>: يقصد بهذه العبارة جميع الخدمات المقدمة على المنصة والمتعلقة بأنشطة السفر والسياحة والإقامة والتنقلات الداخلية والرحلات والأنشطة الترفيهية والتي يقوم بتقديمها مزود الخدمة للعميل عبر المنصة.</p>
<p><strong>الاتفاقية</strong>: يُقصد بهذه العبارة اتفاقية الاستخدام هذه التي تتضمن الشروط والأحكام وسياسة الخصوصية الخاصة بالمنصة وأي اتفاقيات أو عقود خطية أو إلكترونية أو شفوية تتم بين المنصة والمستخدم لغرض تقديم الخدمات.</p>
<p><strong>الأنظمة</strong>: يقصد بهذه العبارة الأنظمة المعمول بها في المملكة العربية السعودية.&nbsp;</p>
<p><br></p>
<p><br></p>
<p><strong>شروط تسجيل العميل على المنصة</strong></p>
<p><b>&bull;&nbsp;</b>يشترط لتسجيل العميل على المنصة أن يكون مكتسب للأهلية الشرعية والنظامية الكاملة وذلك وفقًا للأنظمة المعمول بها في المملكة العربية السعودية.</p>
<p><b>&bull;&nbsp;</b>يجب أن يكون اسم الحساب الخاص بالعميل متوافق مع البيانات الرسمية الخاصة به، ويحظر التسجيل بأسماء غير حقيقية أو مضللة أو غير نظامية أو خاصة بالغير.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل تسجيل الحساب بالمنصة برقم جوال وبريد الكتروني تابعين له، حتى يتمكن من استلام أي إشعارات مرسلة له من المنصة.</p>
<p><b>&bull;&nbsp;</b>يحظر على العميل التسجيل في المنصة بأكثر من حساب وسيتم حذف جميع الحسابات في حال تبيّن وجود أكثر من حساب.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل الحفاظ على اسم المستخدم وكلمة المرور الخاصة به، وألا يقوم بالإفصاح عنها للغير، وفي جميع الأحوال يلتزم العميل بكافة التعاملات التي تتم من خلال حسابه بالمنصة.</p>
<p><b>&bull;&nbsp;</b>في حال لم يحقق العميل أي شرط من شروط هذا البند ستكون هذه الاتفاقية لاغية بالنسبة له مع تطبيق كافة الآثار النظامية الناشئة خلال فترة سريانها على العميل.</p>
<p><br></p>
<p><br></p>
<p><strong>دقة المعلومات المقدّمة لنا</strong></p>
<p>يتعهد العميل بأنّ جميع البيانات والمعلومات لدى المنصة هي حقيقية وصحيحة ومحدّثة وكاملة وقانونية، ويلتزم بتحديث بياناته في حال تغييرها، والمنصة غير مسؤولة عن أي معلومات غير صحيحة مقدّمة من قِبل العميل.</p>
<p><br></p>
<p><strong>تقديم الخدمات</strong></p>
<p><b>&bull;&nbsp;</b>تقوم المنصة بالربط التقني بين مزودي خدمات السفر والسياحة والإقامة والتنقلات الداخلية والرحلات والأنشطة الترفيهية بالباحثين عنها وذلك لتسهيل تقديم تلك الخدمات لطالبيها؛ لذلك وبالرغم من أن المنصة تحرص على التعاقد مع أفضل مزودي الخدمات، إلا أنها غير مسؤولة عن جودة الخدمات المقدّمة من مزودي الخدمات حيث أنّ دورها ينحصر في الربط التقني ومزود الخدمة وحده مسؤول عن ذلك.</p>
<p><b>&bull;&nbsp;</b>تحرص المنصة على تقديم أفضل الأسعار مقارنة بالمواقع الأخرى، والمنصة لا تفرض على العميل أي رسوم إدارية بل أنّ جميع رسومها الإدارية يتم استقطاعها من مزود الخدمة بشكل مباشر إلا في حال ذكرها غير ذلك.</p>
<p><b>&bull;</b> إنّ العروض الترويجية تختلف باختلاف مزود الخدمة، لذلك قد لا تنطبق العروض على جميع الخدمات.</p>
<p><br></p>
<p><br></p>
<p><strong>خدمات الإقامة</strong></p>
<p><b>&bull;&nbsp;</b>تقوم المنصة بإتاحة الفرصة لمزودي الخدمات للترويج عن أماكن الإقامة الخاصة بهم سواء كانت فنادق أو شقق سكنية أو فندقية أو شاليهات أو أكواخ، وذلك بتأجيرها لفترات معينة، وبذلك تمكّن المنصة العميل من البحث عن أماكن الإقامة المناسبة له ولاحتياجاته وتتيح له مقارنتها مع باقي عروض مزودي الخدمات قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>يتقدّم العميل من خلال المنصة باختيار خدمات الإقامة التي يرغب في حجزها، ويقوم بتحديد الموعد المطلوب ومن ثم إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>إنّ الحجوزات التي يقوم بها العميل على المنصة عند اختياره خدمات الإقامة تتم بشكل مباشر وتلقائي مع مزود الخدمة، ولا تعتبر المنصة طرفًا تعاقديًا فيها.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بتعبئة تفاصيل الحجز بشكل سليم وواضح وصحيح حتى تتمكن المنصة ومزود الخدمة من التواصل معه وتزويده بمعلومات الحجز.</p>
<p><b>&bull;&nbsp;</b>المعلومات والخدمات الإضافية المقدمة من مزود الخدمة مثل: المرافق، والإرشادات الخاصة بالإقامة، والأسعار، والتواريخ المتاحة، وسياسة الإلغاء ستكون موضحة للعميل قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>قبل إتمام عملية الحجز يقر العميل بأنّه قام بقراءة التعليمات الخاصة بمزود الخدمة بعناية وأنّه موافق عليها، وأن انتهاكها قد يؤدي إلى فرض رسوم إضافية أو إلغاء الحجز.</p>
<p><b>&bull;&nbsp;</b>بعد إتمام عملية الحجز ودفع الرسوم سوف يصل للعميل بريد إلكتروني بتأكيد الحجز، وفي حال عدم تلقي العميل لرسالة التأكيد يجب عليه التواصل مع المنصة.</p>
<p><b>&bull;&nbsp;</b>إن تغيير أو الغاء الحجز يعتمد على سياسة الإلغاء الخاصة بالحجز والمقدمة من مزود الخدمة وهي تختلف بحسب مزود الخدمة لذلك ننصح بمراجعة المعلومات وسياسة الإلغاء الموضحة قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بالمحافظة على السكن عند استلامه ومرفقاته وما يحتوى عليه، وأن يتركه في نفس الحالة التي كان عليها عندما استلمه، وفي حال قام بإتلاف أي من هذه الممتلكات المتواجدة في السكن أو تسبب في فقدها أو تضررها فعليه إبلاغ الموظفين المسؤولين قبل تسجيل المغادرة ويخضع لسياسة التعويض الخاصة بمزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>قد يرفض مزود الخدمة تعديل أو إلغاء أي من الحجوزات بحسب سياسة الإلغاء الخاصة به وفي جميع الأحوال لا تتحمل المنصة مسؤولية ذلك حيث أنّ دورها يقتصر في الربط التقني بين العميل ومزود الخدمة وعلى العميل التواصل مباشرة مع مزود الخدمة.</p>
<p><br></p>
<p><b>&bull;<strong>&nbsp;</strong></b><strong>الرحلات السياحية والأنشطة الترفيهية</strong></p>
<p><b>&bull;&nbsp;</b>ينحصر دور المنصة في تقديم خدمات الربط التقني بين العميل ومزود الخدمة وهي بذلك تقوم بإتاحة الفرصة لمزودي الخدمات للترويج عن الرحلات السياحية والأنشطة الترفيهية التابعة لهم وتمكّن العميل من البحث عنها ومقارنتها مع باقي عروض مزودي الخدمات قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>المنصة لا تقوم بتقديم أي من الرحلات أو الأنشطة الترفيهية بشكل مباشر كما أنّها غير مسؤولة عن تنظيمها، لذلك فإنّ قيامك بحجز موعد لأي من الرحلات أو الأنشطة الترفيهية على المنصة يُدخلك في علاقة تعاقدية مباشرة مع مزود الخدمة تخضع من خلالها لشروط وأحكام مزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>يتقدّم العميل من خلال المنصة باختيار الرحلات والأنشطة الترفيهية التي يرغب في حجزها، ويقوم بتحديد الموعد المطلوب ومن ثم إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بتعبئة تفاصيل الحجز بشكل سليم وواضح وصحيح حتى تتمكن المنصة أو مزود الخدمة من التواصل معه وتزويده بمعلومات الحجز.</p>
<p><b>&bull;&nbsp;</b>قبل إتمام عملية الحجز يقر العميل بأنّه قام بقراءة التعليمات الخاصة بمزود الخدمة بعناية وأنّه موافق عليها، وأن انتهاكها قد يؤدي إلى فرض رسوم إضافية أو إلغاء الحجز.</p>
<p><b>&bull;&nbsp;</b>بعد إتمام عملية الحجز ودفع الرسوم سوف يصل للعميل بريد إلكتروني بتأكيد الحجز، وفي حال عدم تلقي العميل لرسالة التأكيد يجب عليه التواصل مع المنصة.</p>
<p><b>&bull;&nbsp;</b>إن تغيير أو الغاء الحجز يعتمد على سياسة الإلغاء الخاصة بالحجز والمقدمة من مزود الخدمة وهي تختلف بحسب مزود الخدمة لذلك ننصح بمراجعة المعلومات وسياسة الإلغاء الموضحة قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>قد يرفض مزود الخدمة تعديل أو إلغاء أي من الحجوزات بحسب سياسة الإلغاء الخاصة به وفي جميع الأحوال لا تتحمل المنصة مسؤولية ذلك حيث أنّ دورها يقتصر في الربط التقني بين العميل ومزود الخدمة وعلى العميل التواصل مباشرة مع مزود الخدمة.</p>
<p><br></p>
<p><b>&bull;&nbsp;</b><strong>خدمات الطيران</strong></p>
<p><b>&bull;&nbsp;</b>جميع الرحلات الجوية المقدّمة على المنصة يتم تقديمها من قبل مزود خدمة يعمل كوسيط لشركات الطيران؛ لذلك فإن جميع حجوزات الطيران التي يقوم بها العميل تتم من قبل شركة الطيران ويدخل العميل في علاقتين تعاقدية؛ الأولى مع وسيط شركة الطيران (مزود الخدمة) والثانية مع شركة الطيران مقدمة الرحلة ولا تكون المنصة طرفًا تعاديًا في أيًا منها.</p>
<p><b>&bull;&nbsp;</b>يتقدّم العميل من خلال المنصة باختيار الرحلات التي يرغب في حجزها، ويقوم بتحديد الموعد المطلوب ومن ثم إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بتعبئة تفاصيل الحجز بشكل سليم وواضح وصحيح حتى تتمكن المنصة أو مزود الخدمة أو شركة الطيران من التواصل معه وتزويده بمعلومات الحجز.</p>
<p><b>&bull;&nbsp;</b>قبل إتمام عملية الحجز يقر العميل بأنّه قام بقراءة التعليمات الخاصة بمزود الخدمة بعناية وأنّه موافق عليها، وأن انتهاكها قد يؤدي إلى فرض رسوم إضافية أو إلغاء الحجز.</p>
<p><b>&bull;&nbsp;</b>بعد إتمام عملية الحجز ودفع الرسوم سوف يصل للعميل بريد إلكتروني بتأكيد الحجز، وفي حال عدم تلقي العميل لرسالة التأكيد يجب عليه التواصل مع المنصة.</p>
<p><b>&bull;&nbsp;</b>إن تغيير أو الغاء حجزك يعتمد على سياسة الإلغاء الخاصة بحجزك فهي تختلف بحسب مزود الخدمة لذلك ننصح بمراجعة المعلومات وسياسة الإلغاء الموضحة قبل إتمام الحجز.</p>
<p><b>&bull;&nbsp;</b>قد تقوم شركة الطيران بإعادة جدولة أو إلغاء الرحلات بحسب تقديرها، وذلك لا يدخل ضمن مسؤولية المنصة ويمكن للعميل التواصل مباشرة مع شركة الطيران.</p>
<p><b>&bull;&nbsp;</b>أي خدمات إضافية يرغب العميل بطلبها، يجب عليه التواصل مباشرة مع شركة الطيران وطلب الخدمات.&nbsp;</p>
<p><b>&bull;&nbsp;</b>في حال قام العميل بحجز تذكرة ذهاب دون حجز العودة فقد يحتاج إلى إثبات أنّ لديه رحلة عودة لدى موظفي شركة الطيران.</p>
<p><b>&bull;&nbsp;</b>قد يحتاج العميل إلى تأشيرة سفر للوجهة التي حجز إليها؛ لذلك على العميل الحصول على هذه التأشيرة بنفسه وننصح بالتأكد من أنّ جواز السفر ساري المفعول في فترة السفر وأنّ تاريخ انتهاء صلاحيته معتمد من قبل جميع الدول التي ينوي العميل الدخول إليها. وللمزيد من المعلومات حول المتطلبات الخاصة بجواز السفر والتأشيرة، يجب على العميل التواصل مع سفارة البلد التي سيقوم بزيارتها.</p>
<p><b>&bull;&nbsp;</b>أي رسوم إضافية تفرضها الدولة أو المطار على العميل لا تدخل ضمن رسوم الحجز ويتم تحصيلها بشكل مباشر في المطار، لذلك يجب على العميل التأكد في حال وجود رسوم إضافية.</p>
<p><b>&bull;&nbsp;</b>في جميع الأحوال المنصة غير مسؤولة عن أي متطلبات أو تحذيرات تخص السفر، ولا عن أي أضرار أو خسائر تترتب نتيجة لعدم علم العميل بهذه المتطلبات أو بعدم موافاتها.</p>
<p><br></p>
<p><br></p>
<p><strong><b>&bull;&nbsp;</b>النقل الخاص</strong></p>
<p><b>&bull;&nbsp;</b>إنّ الحجوزات التي يقوم بها العميل على المنصة عند اختياره خدمات النقل الخاص تكون بشكل مباشر مع مزود الخدمة، ولا تعتبر المنصة طرفًا تعاقديًا فيها.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بتعبئة تفاصيل الحجز بشكل سليم وواضح وصحيح حتى تتمكن المنصة أو مزود الخدمة من التواصل معه وتزويده بمعلومات الحجز.</p>
<p><b>&bull;&nbsp;</b>قبل إتمام عملية الحجز يقر العميل بأنّه قام بقراءة التعليمات الخاصة بمزود الخدمة بعناية وأنّه موافق عليها، وأن انتهاكها قد يؤدي إلى فرض رسوم إضافية أو إلغاء الحجز.</p>
<p><b>&bull;&nbsp;</b>بعد إتمام عملية الحجز ودفع الرسوم سوف يصل للعميل بريد إلكتروني بتأكيد الحجز، وفي حال عدم تلقي العميل لرسالة التأكيد يجب عليه التواصل مع المنصة.</p>
<p><b>&bull;&nbsp;</b>مزود الخدمة مسؤول عن ترتيب وتوفير وسيلة النقل الخاصة بالعميل، واختيار المسارات، وتحديد الأسعار، وتوفير جميع المعلومات ذات العلاقة.</p>
<p><b>&bull;&nbsp;</b>إن تغيير أو الغاء حجزك يعتمد على سياسة الإلغاء الخاصة بحجزك فهي تختلف بحسب مزود الخدمة لذلك ننصح بمراجعة المعلومات وسياسة الإلغاء الموضحة قبل إتمام الحجز.</p>
<p><b>&bull;&nbsp;</b>قد يتم إلغاء حجزك لسبب يعود لمزود الخدمة وفي هذه الحالة يجب على العميل التواصل مباشرة مع مزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل ضمان تواجد جميع الركاب في موقع الالتقاء في الوقت المحدد تجنبًا لأي إلغاء.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل التأكد من أن وسائل التواصل التي تم إدخالها في المنصة صحيحة وأن يكون الهاتف متاحًا لاستقبال المكالمات والرسائل النصية حتى يتمكن السائق من الوصول إليه، كما أنّ المنصة لا تضمن تمكّن السائق من الوصول إلى العميل من خلال تطبيقات التواصل الإلكترونية مثل واتساب وغيرها.</p>
<p><b>&bull;&nbsp;</b>في حال تقدم العميل بطلب خدمة التوصيل من المطار فيجب عليه تزويد المنصة بتفاصيل رحلة الطيران الخاصة به قبل ٢٤ ساعة على الأقل من وقت الاستلام، حتى يتمكن مزود الخدمة من تحديد وقت الوصول. وفي حال تأخرت الرحلة يجب على العميل إبلاغ مزود الخدمة بذلك.</p>
<p><b>&bull;&nbsp;</b>قد يفرض مزود الخدمة على العميل رسوم إضافية في حال تم تغيير الرحلة أو مساراتها.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بعدم التصرف بشكل غير لائق وبعدم الاعتداء على سائقي مزود الخدمة لأي سبب من الأسباب.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بعدم التدخين داخل السيارة، وبعدم التسبب بأي تلفيات أو أضرار للسيارة وبالمحافظة على نظافتها طوال مدة الرحلة وفي حال عدم التزام العميل بذلك فسيترتب عليه رسوم تنظيف ٢٥٠ ريال سعودي يتم خصمها مباشرةً من العميل.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بالمحافظة على ممتلكاته الشخصية وبعدم تركها في أي مكان حيث أنّ بونداي غير مسؤولة عنها ولا تضمن سلامتها وهي مسؤولية العميل وحده.</p>
<p><br></p>
<p><strong><span style="font-size: 20px;">الأسعار&nbsp;</span></strong></p>
<p><b>&bull;&nbsp;</b>تبذل المنصة قصارى جهدها لضمان صحة ودقة الأسعار المعروضة، ومع ذلك في حالة وجود خطأ في السعر المعروض فإنّ المنصة تحتفظ بحقها في تعديل الأسعار المعروضة حتى وإن كان ذلك اثناء قيامك بالحجز.</p>
<p><b>&bull;&nbsp;</b>يعلم العميل بأنّ الرسوم الموضحة لكل خدمة هي ثابتة ونهائية ولا يحق للعميل طلب تخفيضها بعد تقديم الطلب.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل سداد رسوم الخدمات عن طريق وسائل الدفع المتوفرة على المنصة، ويحظر على العميل استخدام وسائل دفع غير صحيحة أو غير مشروعة وفي حال مخالفته ذلك فإنّه يتحمل المسؤولية.</p>
<p><b>&bull;&nbsp;</b>قد يطلب مزود الخدمة أن يتم سداد الرسوم مقدمًا عند الحجز أو دفعها عند الوصول إلى مزود الخدمة لذلك فأنت تعلم أن الدفعات المقدمة قد تكون غير قابلة للاسترداد بحسب سياسة الإلغاء الخاصة بمزود الخدمة والتي تكون ظاهرة قبل إتمام عملية الحجز ولا تتحمل المنصة مسؤولية ذلك.</p>
<p><b>&bull;&nbsp;</b>قد يطلب مزود الخدمة رسوم إضافية مقابل أي خدمات أخرى يقدمها ولا تتحمل المنصة مسؤولية ذلك.</p>
<p><b>&bull;&nbsp;</b>يعلم العميل بأنّ جميع المدفوعات التي تتم عبر المنصة يتم تحويلها إلي مزود الخدمة، وأنّ دور المنصة في هذه الحالة هو تنظيم المدفوعات نيابة عن مزود الخدمة.&nbsp;</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">التزامات العميل</span></strong></p>
<p><b>&bull;&nbsp;</b>عند إتمام عملية الحجز فإنّ العميل يوافق على شروط وأحكام وسياسات الإلغاء لكل مزود خدمة وأي سياسات أخرى والتي قد تشمل (متطلبات العمر، مبلغ التأمين، الخدمات الإضافية، الأسرة الإضافية، الوجبات المقدمة، والبطاقات المسموح بها) وغيرها من الشروط التي يجب على العميل الاطلاع عليها وقراءتها والتأكد منها قبل إتمام عملية الحجز.</p>
<p><b>&bull;&nbsp;</b>يجب أن يلتزم العميل بالحضور في الوقت المحدد بحسب التعليمات الموضحة للخدمة التي قام باختيارها، حيث أن في حال تأخره قد يترتب على ذلك إلغاء الحجز وفي هذه الحالة لن تتحمل المنصة مسؤولية ذلك، ولا يحق للعميل المطالبة باسترداد الرسوم المدفوعة.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل الاستفادة بنفسه من الخدمة المقدمة، ويحظر عليه تفويض أي أشخاص آخرين في الاستفادة من تلك الخدمات، وسيكون العميل مسؤول بشكل كامل ونهائي عن أي أضرار تترتب نتيجة مخالفة ذلك.</p>
<p><b>&bull;&nbsp;</b>يتعهد العميل بأنّ جميع المعلومات والبيانات التي يقدّمها صحيحة وسليمة ومتأكدًا منها، وإلّا عُدّ وحده مسؤولًا عن أي أضرار قد تنشأ نتيجة لعدم صحة هذه المعلومات والبيانات.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بتقديم العون والتسهيلات اللازمة في حال قيام المنصة بإجراء أي تحقيقات بشأن أي استخدام نظن أنه يخالف أحكام هذه الاتفاقية أو يخالف الأنظمة المعمول بها أو يتسبب في نزاعات قانونية للمنصة أو يضر بأي من مستخدمي المنصة.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بعدم استخدام المنصة لأغراض غير مشروعة أو غير حقيقية أو عمل حجوزات وهمية.</p>
<p><b>&bull;&nbsp;</b>يجب أن يحافظ العميل على سمعة المنصة وألا يسيء لها بشكل مباشر أو غير مباشر، وأن لا يتسبب للمنصة بأي أضرار أو مطالبات قانونية ويحق للمنصة الرجوع عليه قضائيًا في حال مخالفته ذلك.</p>
<p><b>&bull;&nbsp;</b>يلتزم العميل بأحكام وشروط اتفاقية الاستخدام هذه وفي حال مخالفته لأي من بنود هذه الاتفاقية فيحق للمنصة - دون أي إنذار مسبق - أن تقوم بحذف حساب العميل، ولا تتحمل على ذلك أي تعويض أو التزام. كما يحق للمنصة المطالبة بالتعويض عن أي أضرار تنشأ نتيجة إخلال العميل لالتزاماته المنصوص عليها في هذه الاتفاقية.</p>
<p><br></p>
<p><br></p>
<p><span style="font-size: 20px;">تعديل وإلغاء الحجز</span></p>
<p><strong><b>&bull;&nbsp;</b>التعديلات/التغييرات على الحجز</strong></p>
<p><b>&bull;&nbsp;</b>عند قيامك بالحجز لأي من الخدمات المقدمة على المنصة، فأنت تعلم بأنّ الخدمة التي تقدمت إليها تخضع لشروط وأحكام مزود الخدمة ولسياسة التعديل والإلغاء الخاصة به. لذلك فإنك توافق على الشروط والأحكام وسياسة التعديل والإلغاء المنصوص عليها تحت كل خدمة.</p>
<p><b>&bull;&nbsp;</b>يجب على العميل قراءة سياسة الإلغاء الخاصة بكل خدمة قبل إتمام عملية الحجز حيث أنّ سياسة الإلغاء تختلف من مزود خدمة لآخر.</p>
<p><b>&bull;&nbsp;</b>في حال قام العميل بإلغاء الحجز أو عدم الحضور في الموعد المحدد، فإنّ أي رسوم إلغاء أو عدم حضور أو أي استرداد للمبالغ يعتمد على سياسة الإلغاء الخاصة بمزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>بالنسبة للخدمات التي يكون فيها الدفع مقدمًا، يحق لمزود الخدمة إلغاء الحجز دون إشعار العميل في حال امتنع العميل عن السداد في الوقت المحدد.</p>
<p><br></p>
<p><strong>إذا قامت المنصة بإجراء تغييرات على الحجز&nbsp;</strong></p>
<p>في بعض الأحيان قد يكون من الضروري إجراء تغييرات على حجز العميل بعد قبوله سواء من قبلنا أو من قبل مزود الخدمة. في جميع الأحوال، سنقوم بإخبار العميل بذلك في أقرب وقت قبل موعد الخدمة، وفي حال كانت التغييرات المقترحة غير مقبولة فسوف نعرض على العميل استرداد كامل المبلغ. مع ذلك، لن نتحمل أي مسؤولية في حال ترتبت على العميل أي خسائر بشكل مباشر أو غير مباشر نتيجة لهذه التغييرات.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">الأطراف الثالثة</span></strong></p>
<p><b>&bull;&nbsp;</b>نتعاقد في المنصة مع بعض الأطراف الأخرى لدعم المنصة وتشغيلها بالشكل الأمثل، فعلى سبيل المثال نتعاقد مع مزود خدمة دفع إلكتروني لتأمين عمليات الدفع الخاصة بالعميل، وتوفير وسائل دفع متعددة من جانب هذه الجهات المتعاقدة، كما أننا في المنصة متعاقدين مع جهات استضافة المواقع والتطبيقات لتخزين المحتوى والبيانات والمنصة بشكل كامل لضمان سير عملها بشكل دائم ودون انقطاع أو فقد محتوى أو بيانات.</p>
<p><b>&bull;&nbsp;</b>يفوضنا المستخدم في التعامل مع الأطراف المتعاقدة نيابة عنه، ويكون ملزمًا بكافة العقود التي نبرمها مع هذه الجهات وبأي محاذير استخدام، فعمل هذه الجهات أصبح جزء لا يتجزأ من عمل المنصة، وفي حال اعتراض المستخدم على التعاقد مع هذه الجهات فهذا يعني عدم رغبته في الاستفادة من خدمات المنصة بشكل كامل.</p>
<p><b>&bull;&nbsp;</b>قد نقوم بتغيير الأطراف المتعاقدة من وقت لآخر، ولأسباب مختلفة، كما قد نقوم بالتعاقد مع أطراف جديدة، ولن نقوم في هذه الحالات باستطلاع رأيك أو الحصول على موافقتك، بل سنقوم بذلك من تلقاء أنفسنا دون الحاجة إلى الرجوع إليك.</p>
<p><b>&bull;&nbsp;</b>قد يساعدنا في تقديم خدمات المنصة أطراف ثالثة غير متعاقدة معنا، وذلك عن طريق الربط بين خدماتنا وخدماتهم لأغراض مشتركة، كالجهات المالكة لمواقع وتطبيقات أخرى مثل مواقع وتطبيقات التواصل الاجتماعي، فقد نقوم بتوفير روابط لحسابات عبر هذه المواقع، وبالنقر عليها سيتم إحالة المستخدم إليها، وهذه الجهات تتبع سياسات خاصة بها قد تختلف بشكل كلي أو جزئي عن السياسات التي نتبعها في المنصة، لذا يجب عليك الاطلاع على هذه السياسات قبل استخدام خدمات هذه الجهات.&nbsp;</p>
<p><b>&bull;&nbsp;</b>تُخلي المنصة مسؤوليتها القانونية عن أي أخطاء مباشرة أو غير مباشرة، متعمدة أو غير متعمدة، تقع من الأطراف الثالثة التي تقوم بتقديم الخدمات عبر المنصة، فأنت تستخدم خدمات هذه الجهات غير المتعاقدة على مسؤوليتك الشخصية.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">مسؤولية المنصة</span></strong></p>
<p><b>&bull;&nbsp;</b>يقتصر عمل المنصة على الربط بين مزود الخدمة والعميل، ولا تقوم المنصة بتقديم الخدمات بنفسها، لذلك فإن المنصة لا تتحمل مسؤولية الخدمات المقدمة من مزودي الخدمات.</p>
<p><b>&bull;&nbsp;</b>تحرص المنصة على اختيار أفضل مزودي الخدمات للتعامل معهم وذلك لضمان جودة الخدمات المقدمة ولكنها غير مسؤولة بأي شكل من الأشكال عن أي خدمة غير مرضية أو متأخرة من قِبل مزود الخدمة ولا عن أي خسائر أو أعطال تنتج عن هذه الخدمة أو عن عدم جودتها، أو عن التأخر في تقديمها من قِبل مزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>تحرص المنصة على بذل العناية المعقولة في ضمان دقة المعلومات المقدمة من مزودي الخدمات، ولكن لا تتحمل المسؤولية عن أي خطأ أو عدم وضوح في أي من هذه المعلومات.</p>
<p><b>&bull;&nbsp;</b>المنصة لا تتحمل أي مطالبات تنشأ عن أخطاء أو إهمال أو تقصير مزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>المنصة غير مسؤولة عن أي مطالبة أو نزاع أو أضرار أو أي مسؤولية نشأت بشكل مباشر أو غير مباشر من مزود الخدمة.</p>
<p><b>&bull;&nbsp;</b>المنصة قد تتوقف من وقت لآخر، وقد تتوقف بشكل دائم سواء لأسباب إرادية أو غير إرادية، وبالتالي فأنت تعفينا من أي مسؤولية قانونية في حالة التوقف الدائم أو المؤقت للمنصة أو أي من خدماتها.</p>
<p><b>&bull;&nbsp;</b>يعلم العميل بأنّه لا يحق لأي شخص أو جهة الرجوع على المنصة بأي دعوى أو مطالبة أو تعويض فيما يخص إخلال مزود الخدمة لأي من التزاماته، أو في حال تقصيره أو إهماله أو ارتكابه أي خطأ في تقديم خدماته وذلك لما للمنصة من شخصية قانونية مستقلة ومزود الخدمة وحده مسؤول عن أي إخلال للاتفاقية أو الأنظمة المعمول بها.</p>
<p><br></p>
<p>تقييد الولوج إلى المنصة&nbsp;</p>
<p>في حال تبيّن للمنصة قيام العميل بإخلال أي من التزاماته المنصوص عليها في اتفاقية الاستخدام هذه أو مخالفته للأنظمة واللوائح والتعاميم والقواعد المتعلقة بتقديم الخدمات ذات العلاقة، فللمنصة - دون الحاجة إلى إنذار العميل - الحق في إيقاف أو تعليق أو تقييد وصول العميل للمنصة أو التسجيل فيها مرة أخرى ولا يحق للعميل المطالبة بأي تعويض أو تحميل المنصة أي مسؤولية جرّاء ذلك. كما يحق للمنصة اتخاذ كافّة الاجراءات القانونية، وإبلاغ السلطات المختصة عن أي ممارسات مخالفة يقوم بها العميل في حال تطلب ذلك.</p>
<p><br></p>
<p><strong><span style="font-size: 20px;">مسؤولية العميل</span></strong></p>
<p>يتعهد العميل بحماية المنصة من أي أضرار قد تقع عليها جرّاء مخالفته للشروط والأحكام المنصوص عليها في هذه الاتفاقية أو لأي من الأنظمة المعمول بها، كما يلتزم برفع ومنع أي ضرر قد يقع على المنصة نتيجة لمطالبات أو خسائر أو أعطال أو رسوم بسبب اساءة استخدامه للمنصة أو مخالفته للأنظمة ولاتفاقية الاستخدام.</p>
<p><br></p>
<p><strong><span style="font-size: 20px;">التعويضات</span></strong></p>
<p><b>&bull;&nbsp;</b>المنصة لا تقدم أي نوع من أنواع التأمين أو التعويضات لأي من مستخدميها أو العملاء، سواء عن الأضرار الناشئة عن خطأ من جانب مزودي الخدمات أو العملاء أو عن أي إهمال يقع من جانب المنصة أو موظفيها أو المسؤولين عن تشغيلها.</p>
<p><b>&bull;&nbsp;</b>يلتزم المستخدم بتعويض المنصة تعويضًا كاملًا عن كافة الأضرار المادية والمعنوية التي تنشأ عن الأفعال غير المشروعة التي يقوم بها من خلال المنصة، أو الناشئة بشكل عام عن استخدامه للمنصة أو عدم الالتزام بأي من شروطها وأحكامها أو عدم الالتزام بالقوانين المعمول بها.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">الملكية الفكرية</span></strong></p>
<p><b>&bull;&nbsp;</b>يجب على المستخدم احترام حقوق الملكية الفكرية الخاصة ببونداي وهي على سبيل المثال لا الحصر: المنصة، والكلمات، والشعارات، والصور، والفيديوهات، والأصوات، والرموز الخاصة ببونداي أو المعروضة على المنصة.</p>
<p><b>&bull;&nbsp;</b>بونداي وكل حق يتبعها هي حقوق محمية بموجب أنظمة الملكية الفكرية والعلامات التجارية، وتُعدّ ملكية خاصة ببونداي ولتابعيها وللجهات المرخص لها ولا يحق بأي حال من الأحوال التعدي عليها أو استخدامها دون تفويض من بونداي.</p>
<p><b>&bull;&nbsp;</b>في حال تم التعدي أو التجاوز على أي حق من حقوق بونداي أو ملكيتها الفكرية، ستقوم بونداي باتخاذ الاجراءات اللازمة حيال هذه التجاوزات.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">الإخطارات القانونية</span></strong></p>
<p><b>&bull;&nbsp;</b>كافة الإخطارات القانونية المطلوب تبليغها وفقًا للأنظمة السعودية يتم إرسالها عبر وسائل الاتصال التي زودنا بها العميل، والتي تشمل الاتصال بالهاتف أو الرسائل عبر الهاتف أو عبر البريد الالكتروني، وتحل هذه الإخطارات الهاتفية أو الإلكترونية محل كافة الإخطارات الخطية المطلوبة نظامًا، وتكون معتمدة بشكل رسمي بين الأطراف.</p>
<p><b>&bull;&nbsp;</b>يقر كل مستخدم بتنازله عن أي حقوق قانونية في تلقي أي إخطارات خطية من جانب المنصة، ويقر بأنّ المنصة غير ملزمة بتقديم الإخطارات الخطية ويحل محلها الإخطارات الهاتفية أو الإلكترونية في إنتاج آثارها القانونية على النحو المشار إليه أعلاه، ويتم الاعتداد بها أمام كافة الجهات الرسمية.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 18px;">حماية البيانات</span></strong></p>
<p>
تقرّ وتوافق أنّ استخدامك للمنصة بما في ذلك المعلومات التي يتم إرسالها لنا أو التي نقوم بتخزينها تخضع      
<a class='primary--text cursor-pointer' href="https://beta.bondai.io/privacy-policy"><strong>
لسياسة الخصوصية الخاصة
</strong></a>
<p><br></p>
<p><strong><span style="font-size: 18px;">ملفات تعريف الارتباط وتقنيات التتبع الأخرى</span></strong></p>
<p>قد نستخدم ملفات تعريف الارتباط وتقنيات أخرى لتسهيل وتتبع استخدامك للخدمات المقدمة على المنصة. لمزيد من المعلومات حول ذلك يرجى الاطلاع على 
<a class='primary--text cursor-pointer' href="https://beta.bondai.io/privacy-policy"><strong>

لسياسة الخصوصية الخاصة</strong></a>
</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">القانون واجب التطبيق</span></strong></p>
<p>تخضع هذه الاتفاقية إلى جميع الأنظمة واللوائح ذات العلاقة والمعمول بها في المملكة العربية السعودية.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 18px;">تلقي الشكاوى</span></strong></p>
<p>في حال واجهتك مشكلة أو ترغب في تقديم شكوى بإمكانك التواصل معنا عن طريق فريق خدمة العملاء، وفي حال تبين أنّ الشكوى المقدّمة هي من شأن مزود الخدمة فسيتم إبلاغك بذلك وتزويدك ببيانات التواصل مع مزود الخدمة لتقديم شكواك لهم بشكل مباشر. وبونداي تخلي مسؤوليتها تجاه أي شكوى مقدّمة تخص مزود الخدمة وذلك لأنك عندما تقوم بحجز خدمة فأنت تدخل في علاقة تعاقدية مستقلة مع مزود الخدمة وتخضع للشروط والاحكام المنصوص عليها لدى موقع مزود الخدمة.</p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">أحكام عامة</span></strong></p>
<p><b>&bull;&nbsp;</b>المنصة هي الجهة الوحيدة المختصة بتفسير أو تأويل أحكام هذه الاتفاقية، لذلك عليك التواصل معنا على الفور في حال عدم وضوح أي من هذه الشروط بالنسبة لك.</p>
<p><b>&bull;&nbsp;</b>في حال إلغاء أي بند من بنود اتفاقية الاستخدام هذه، فإنّ هذا لا يلغي صلاحية باقي البنود الواردة في اتفاقية الاستخدام وتظل سارية حتى إشعار آخر.</p>
<p><b>&bull;&nbsp;</b>تحتفظ المنصة بحقها في تغيير اتفاقية الاستخدام هذه من وقت لآخر، وسوف نقوم بإبلاغك عن أي تحديثات أو تعديلات تطرأ على اتفاقية الاستخدام هذه من خلال نشر اتفاقية الاستخدام الجديدة والتي تشير إلى تاريخ آخر تعديل أو تحديث. لذلك يُرجى مراجعة اتفاقية الاستخدام هذه بشكل دوري لمعرفة أي تغييرات.</p>
<p><b>&bull;&nbsp;</b>إذا تمت ترجمة اتفاقية الاستخدام هذه لأي لغة أخرى، فإنّ اللغة العربية هي اللغة المعمول بها في كافة التعاملات.</p>
<p><br></p>

<p><strong><span style="font-size: 18px;">تاريخ النشر: 15/02/2023&nbsp;</span></strong></p>
<p><br></p>
    `,

    refundTitle: "سياسة الاسترجاع",
    refundHereSentence: `بناءً على القوانين المعمول بها في بلدك ، قد يكون لك الحق في ذلك
طلب الوصول إلى المعلومات الشخصية التي نجمعها منك ، تغيير
هذه المعلومات ، أو حذفها في بعض الظروف. لطلب
مراجعة معلوماتك الشخصية أو تحديثها أو حذفها ، يرجى إرسال ملف
طلب نموذج عن طريق النقر`,
    here: "هنا",
    refunds: `
    
    <p style='margin-bottom: 5px !important; margin-top: 20px !important'>• من الممكن بدء طلب إلغاء الحجز عن طريق الاتصال بفريق خدمة عملاء Bondai عبر عنوان البريد الإلكتروني المخصص (<a data-fr-linked="true" href="mailto:hi@bondai.io">hi@bondai.io</a>) أو الاتصال الهاتفي (+966 55507 4410) أو حسابات وسائل التواصل الاجتماعي الرئيسية (انستغرام ، واتساب ، تويتر) .</p>
    <p style='margin-bottom: 15px !important; margin-right: 20px'>• من المهم ملاحظة أنه يجب تقديم طلب الإلغاء الرسمي عبر بوابة الحجز Bondai من الحساب المستخدم لإجراء الحجز.</p>
    <p style='margin-bottom: 5px !important'>• نظرًا لأن سياسات الإلغاء قد تختلف بين الباقات ، يرجى الرجوع إلى سياسة إلغاء الحزمة المحددة قبل إجراء الحجز أو بدء طلب الإلغاء.</p>
    <p style='margin-bottom: 5px !important'>• في حالة عدم تحديد سياسة إلغاء في وقت الحجز ، فإن الطلبات الخاصة غير قابلة للاسترداد. وسيكون ما يلي قابلاً للتطبيق على الرحلات والتجارب الأخرى:</p>
    <p style='margin-bottom: 5px !important; margin-right: 20px'>• قابلة للاسترداد بالكامل حتى 7 أيام قبل تاريخ بدء التجربة.</p>
    <p style='margin-bottom: 15px !important; margin-right: 20px'>• تخضع لرسوم إلغاء بنسبة 100٪ من المبلغ الإجمالي قبل أقل من 7 أيام من تاريخ مغادرة التجربة.</p>
    <p style='margin-bottom: 5px !important'>• في حالة وجود حالة قوة قاهرة ، يجوز لشركة Bondai تعديل أو تغيير أو إلغاء الخدمات المحجوزة وسوف تتصل بالعميل في أقرب وقت ممكن لإبلاغه بالتغييرات التي تم إجراؤها.</p>
    <p style='margin-bottom: 5px !important'>• تشمل حالات القوة القاهرة ، على سبيل المثال لا الحصر ، الحجوزات الزائدة وتواريخ التوقف والكوارث الطبيعية والاضطرابات المدنية وأي أحداث أخرى غير متوقعة قد تؤثر على الخدمات المحجوزة.</p>
    <p style='margin-bottom: 15px !important; margin-right: 20px'>• سيتم تقييم هذه الحالات بشكل فردي لفهم إمكانية تغيير التاريخ أو استرداد الأموال ، يرجى الاتصال بخدمة عملاء Bondai لمتابعة هذه الطلبات وللحصول على أي معلومات إضافية.</p>
    <p style='margin-bottom: 5px !important'>• لا تتحمل بونداي أي مسؤولية عن خدمات التأشيرات المرتبطة بالباقات. تقع على عاتق العميل مسؤولية التأكد من أن مستندات الهجرة المناسبة مرتبة ومحدثة</p>`,

    privacyTitle: "سياسة الخصوصية",
    privacyHereSentence: `    بناءً على القوانين المعمول بها في بلدك ، قد يكون لك الحق في ذلك
طلب الوصول إلى المعلومات الشخصية التي نجمعها منك ، تغيير
هذه المعلومات ، أو حذفها في بعض الظروف. لطلب
مراجعة معلوماتك الشخصية أو تحديثها أو حذفها ، يرجى إرسال ملف
طلب نموذج عن طريق النقر`,
    privacy: `
    
    <p><strong><span style="font-size: 20px;">سياسة الخصوصية</span></strong></p>
<p><br></p>
<p>شركة بونداي العربية للسفر والسياحة (&quot;بونداي&quot;) تؤمن بأنّ حماية بياناتك الشخصية هو أمر مهم وتريد أن تحيطك علمًا كمستخدم لموقع/تطبيق بوندايwww.bondai.io &nbsp;(الـ&quot;منصة&quot;) بكيفية جمع واستخدام ومعالجة وكشف معلوماتك الشخصية وبآلية السرية والخصوصية المعمول بها في بونداي لذلك قامت شركة بونداي بإنشاء سياسة الخصوصية هذه لإظهار مدى التزامها بحماية خصوصية بيانات المستخدمين على منصتها ولتوضيح ماهي المعلومات التي نقوم بجمعها أثناء استخدامك لخدماتنا (الـ&quot;خدمات&quot;).&nbsp;</p>
<p><br></p>
<p><strong>يرجى قراءة سياسة الخصوصية هذه، حيث أنّ بولوجكم إلى منصة بونداي واستخدامكم لها فإنّ جميع معلوماتكم تخضع لهذه السياسة.</strong></p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 18px;">ماذا تغطي سياسة الخصوصية؟</span></strong></p>
<p>تغطي سياسة الخصوصية هذه المعلومات التي تحصل عليها منصة بونداي وتحتفظ بها في أنظمتها الإلكترونية وهي:</p>
<p>•&nbsp; المعلومات والبيانات الشخصية التي تقدمها لنا.</p>
<p>•&nbsp; معلومات يتم جمعها تلقائيًا عند استخدامك لخدمات المنصة.</p>
<p>•&nbsp; المعلومات الشخصية التي تكون من مصادر خارجية.</p>
<p><br></p>
<p><strong>&nbsp;المعلومات الشخصية التي تقدمها لنا</strong></p>
<p>المعلومات الشخصية هي معلومات ذات طابع شخصي يزودها العميل عند استخدامه لخدمات المنصة والتي تمكّن شركة بونداي من التعرّف على المستخدمين على منصتها ومنها &ndash; على سبيل المثال لا الحصر &ndash; البيانات التالية:</p>
<p><strong>بيانات الحساب</strong>: عندما تقوم بالتسجيل للحصول على حساب معنا، فإنّنا نطلب منك معلومات شخصية معينة لفتح حسابك، مثل اسمك ورقمك، وعنوان بريدك الإلكتروني، وكلمة المرور.</p>
<p><strong>بيانات الدفع</strong>: نقوم بجمع تفاصيل الدفع الخاصة بك، وأرقام الحساب المصرفي، ومعلومات المعاملات والتي يمكن معالجتها وتخزينها بواسطة واحد أو أكثر من مزودي خدمة الدفع من الأطراف الثالثة أو شركات بوابات الدفع.</p>
<p><br></p>
<p><strong>معلومات يتم جمعها تلقائيًا عند استخدامك لخدمات منصة بونداي</strong></p>
<p>هي معلومات غير شخصية يقوم متصفحك بإرسالها إلى خوادمنا تلقائيًا وتُعرف ببيانات السجل وهي على سبيل المثال لا الحصر البيانات التالية:</p>
<p><strong>بيانات الاستخدام</strong>: نقوم بجمع معلومات حول تفاعلك مع الخدمات المقدّمة، مثل الصفحات أو المحتويات التي تستعرضها على التطبيق، وعمليات البحث التي تجريها، ومعلومات حول نشاطك والمدة التي تقضيها على الصفحة أو الشاشة، ومسارات التنقل بين الصفحات.</p>
<p><strong>بيانات الموقع</strong>: قد نجمع معلومات حول موقعك على سبيل المثال: المدينة/الدولة المرتبطة بعنوان بروتوكول الانترنت &quot;IP&quot; الخاص بك. كما أنّه عند استخدام خاصية مشاركة الموقع مع تطبيق بونداي، قد نقوم بجمع معلومات الموقع لجهازك المحمول. ضع في اعتبارك أنّ معظم الأجهزة المحمولة تسمح لك بالتحكم في تفعيل أو تعطيل خدمات الموقع لأي تطبيق على جهازك المحمول في قائمة إعدادات الجهاز.</p>
<p><strong>بيانات الجهاز</strong>: نقوم بجمع معلومات حول جهاز الكمبيوتر أو الجهاز المحمول الخاص بك، مثل نوع نظام التشغيل ورقم الإصدار، الشركة المصنعة، نوع المتصفح المستخدم، دقة الشاشة، معرفات الجهاز، عنوان IP، أو معلومات الموقع العامة مثل المدينة أو الدولة أو المنطقة الجغرافية.</p>
<p>ملفات تعريف الارتباط والتقنيات المشابهة: كما هو شائع في العديد من المواقع الإلكترونية الأخرى، يتم إرسال ملفات تعريف الارتباط إلى الجهاز الخاص بك. الغرض منها هو فهم آلية تفاعلك واستخدامك للخدمات المقدّمة على منصة بونداي، وذلك لقياس أداء المستخدمين وتحسين الخدمات المقدّمة على المنصة.</p>
<p><strong>المراسلات</strong>: عندما تقوم بالتواصل أو بالمراسلات مع خدمة العملاء، عبر الهاتف، أو البريد الإلكتروني، أو وسائل التواصل المعلنة في المنصة فإننا نجمع المعلومات المتعلقة بالاتصال وأي معلومات أخرى تقوم بتقديمها لنا.</p>
<p><br></p>
<p><strong>المعلومات الشخصية التي تكون من مصادر خارجية</strong></p>
<p>هي معلومات شخصية يتم الحصول عليها عند استخدامك لخدمات الطرف الثالث كقيامك بتسجيل الدخول إلى خدمات منصة بونداي من خلال موقع أو تطبيق تابع لجهة أخرى (على سبيل المثال: قوقل، فيس بوك، أبل)، والتي تقوم هذه الجهة بتفويضنا للوصول إلى معلومات التسجيل وبيانات الملف الشخصي الخاصة بك. لذلك أنت توافق على أنّه يجوز لبونداي جمع هذه المعلومات وتخزينها واستخدامها وفقًا لسياسة الخصوصية هذه. كما أنّنا غير مسؤولين عن كيفية استخدام تلك الأطراف الثالثة لمعلوماتك ومشاركتها، لذا يرجى الرجوع إلى سياسات الخصوصية لتلك الأطراف الثالثة لفهم كيفية استخدامهم لمعلوماتك الشخصية ومشاركتها.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">لماذا نستخدم معلوماتك الشخصية؟</span></strong></p>
<p>نستخدم معلوماتك الشخصية لتحقيق أغراض مختلفة وذلك - على سبيل المثال لا الحصر &ndash; من أجل:</p>
<p><strong>فتح حساب</strong>: نستخدم بياناتك الشخصية لإنشاء الحساب الخاص بك على منصة بونداي، والتي تمكنك من استخدام المنصة، ومعالجة المعاملات والمدفوعات، والاستفادة من الخدمات المقدمة، والرد على استفساراتك.</p>
<p><br></p>
<p><strong>خدمة العملاء</strong>: يحتاج فريق خدمة العملاء إلى الاطلاع على بياناتك الشخصية والتفاصيل الخاصة بك من أجل الرد على استفساراتك وتقديم الخدمات وتقديم المساعدة التي تحتاجها.</p>
<p><br></p>
<p><strong>أنشطة التسويق</strong>: نستخدم معلوماتك الشخصية لأغراض تحليلية أو إحصائية وذلك لتحقيق أغراض الترويج والتسويق والإعلان.</p>
<p><br></p>
<p><strong>التواصل معك</strong>: نستخدم معلوماتك الشخصية للتحقق من حسابك ولأي أغراض تشغيلية وإعلانية مثل إدارة الحساب أو توفير الدعم الفني، ويشمل ذلك إرسال رسائل لك بشكل دوري عن العروض الترويجية ذات العلاقة بالخدمة.</p>
<p><br></p>
<p><strong>تحسين خدماتنا</strong>: نستخدم أيضًا بياناتك الشخصية لأغراض التحليل وتحسين خدماتنا وآلية تقديمها.</p>
<p><br></p>
<p>الأغراض القانونية: قد نحتاج إلى استخدام معلوماتك الشخصية للتعامل مع الدعاوى والنزاعات القانونية وحلها، أو لتطبيق الشروط والأحكام الخاصة بنا أو الامتثال للطلبات القانونية من الجهات والسلطات المختصة.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">من هم الأطراف الثالثة التي نشارك معها بياناتك الشخصية؟</span></strong></p>
<p><strong>مزودي الخدمات</strong>: قد نستخدم مزودي خدمة من خارج الشركة وذلك لدعمنا في تقديم خدماتنا وهم &ndash; على سبيل المثال لا الحصر &ndash; مقدمي الخدمات التالية:</p>
<p>جهات استضافة المواقع والتطبيقات لتخزين المحتوى والمنصة والبيانات.</p>
<p>الجهات المقدمة لخدمات مركز الاتصال، خدمات العملاء، إدارة علاقات العملاء.</p>
<p>بوابات الدفع.</p>
<p>&nbsp;</p>
<p><strong>الاستجابة للطلبات القانونية</strong>: قد نكشف عن معلوماتك الشخصية للمحاكم، أو الجهات الحكومية أو الضريبية، أو أي أطراف ثالثة ذات علاقة وذلك بالحد الذي يُسمح لنا فيه بموجب القانون المعمول به.</p>
<p><br></p>
<p><strong>شركاء العمل</strong>: قد نفصح عن معلوماتك الشخصية مع شركائنا في العمل وذلك في حدود تزويدك بالخدمات المطلوبة التي تختارها على الموقع.</p>
<p><br></p>
<p><strong>دون ذلك، ما لم نحصل على موافقتك، لن نقوم بمشاركة معلوماتك الشخصية لأي أطراف ثالثة أخرى بأي شكل من الأشكال.</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong><span style="font-size: 18px;">أمن المعلومات</span></strong></p>
<p>نبذل قصارى جهدنا في اتخاذ كافة التدابير الوقائية وتطبيق كافة الاجراءات الفنية والمادية والتنظيمية من أجل حماية معلوماتك من أي وصول غير مصرح له اثناء نقل أو تخزين أي من بياناتك الشخصية. ومع ذلك، لا يمكن أن يكون هناك طريقة آمنة بنسبة 100% في نقل المعلومات أو تخزينها أو حمايتها لذلك لا يمكننا أن نضمن أمان شبكة الإنترنت لما قد تطرأ من اختراقات على أنظمة الحماية.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">عمليات نقل البيانات</span></strong></p>
<p>قد نقوم بنقل وتخزين البيانات التي نجمعها عنك في دول أخرى غير الدول التي تم فيها جمع البيانات، بما في ذلك الولايات المتحدة أو كندا أو دولة أخرى خارج المملكة العربية السعودية أو المنطقة الاقتصادية الأوروبية (&quot;EEA&quot;) أو المملكة المتحدة، وقد لا يكون لهذه الدول نفس قوانين حماية البيانات مثل الدولة التي قدمت فيها البيانات. عندما ننقل بياناتك إلى دول أخرى، سنقوم بحمية البيانات كما هو موضح في سياسة الخصوصية هذه وسنلتزم بالمتطلبات القانونية المعمول بها لتوفير الحماية الكافية لنقل البيانات إلى دول خارج المملكة العربية السعودية أو المنطقة الاقتصادية الأوروبية أو المملكة المتحدة.</p>
<p><br></p>
<p>إذا كنت مقيمًا في المملكة العربية السعودية أو المنطقة الاقتصادية الأوروبية أو المملكة المتحدة، فلن نقوم بنقل بياناتك الشخصية إلا إذا وضعنا ضمانات مناسبة فيما يتعلق بالنقل المقبول بموجب قوانين المملكة العربية السعودية أو المنطقة الاقتصادية الأوروبية أو المملكة المتحدة، على أن تكون معتمدة من الجهات المختصة في هذه الدول.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">ما هي المدة التي نحتفظ فيها بالبيانات الشخصية؟</span></strong></p>
<p>نحتفظ ببياناتك الشخصية بالقدر الذي نحتاجه لتحقيق الأغراض التي تم جمع بياناتك من أجلها ما لم يُطلب منا أو يسمح لنا النظام الاحتفاظ بها لمدة أطول.</p>
<p><br></p>
<p>&nbsp;</p>
<p><strong><span style="font-size: 18px;">تغييرات على سياسة الخصوصية</span></strong></p>
<p>تحتفظ شركة بونداي بحقها في تغيير سياسة الخصوصية هذه من وقت لآخر، وسوف نقوم بإبلاغك عن أي تحديثات أو تعديلات تطرأ على سياسة الخصوصية هذه من خلال نشر سياسة الخصوصية الجديدة والتي تشير إلى تاريخ آخر تعديل أو تحديث. لذلك يُرجى مراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">تعديل وتحديث بياناتك الشخصية</span></strong></p>
<p>يمكنك مراجعة وتصحيح وتحديث وتحرير بعض المعلومات التي قدمتها إلينا مسبقًا في أي وقت عن طريق تسجيل الدخول إلى حسابك ومراجعة إعدادات حسابك وملفك الشخصي. يمكنك أيضًا الوصول إلى معلوماتك أو طلب تصحيحها عن طريق الاتصال بنا، وقد نحتاج إلى التحقق من هويتك قبل تنفيذ طلبك.</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">الموافقة</span></strong></p>
<p>بقراءتك لسياسة الخصوصية هذه فأنت توافق على جمع واستخدام وكشف المعلومات الشخصية الخاصة بك وفقًا لما تمّ توضيحه في هذه السياسة، وفي حال عدم موافقتك على ذلك فيرجى عدم تزويدنا بأي من معلوماتك الشخصية.&nbsp;</p>
<p><br></p>
<p><strong><span style="font-size: 18px;">تاريخ النشر: 15/02/2023</span></strong></p>
<p><br></p>
<p><br></p>
    
    `,

    cookiesTitle: "اتفاقية ملفات تعريف الارتباط",
    cookiesHereSentence: `
بناءً على القوانين المعمول بها في بلدك ، قد يكون لك الحق في ذلك
طلب الوصول إلى المعلومات الشخصية التي نجمعها منك ، تغيير
هذه المعلومات ، أو حذفها في بعض الظروف. لطلب
مراجعة معلوماتك الشخصية أو تحديثها أو حذفها ، يرجى إرسال ملف
طلب نموذج عن طريق النقر`,
    cookies: `

<div>
<p>
تم التحديث الأخير في 11 سبتمبر 2019
</p>
<p>
<br/>
شكرًا لاختيارك أن تكون جزءًا من مجتمعنا في Bondai inc.
("الشركة" ، "نحن" ، "لنا"). نحن ملتزمون بحماية
المعلومات الشخصية وحقك في الخصوصية. إذا كان لديك أي
أسئلة أو مخاوف بشأن إشعار الخصوصية هذا ، أو ممارساتنا مع
فيما يتعلق بمعلوماتك الشخصية ، يرجى الاتصال بنا على <a href="mailto:hi@bondai.io"> hi@bondai.io </a>.
</p>
<p>
<br/>
عندما تزور موقعنا <a href="https://www.bondai.io/"> https://www.bondai.io </a> (
"الموقع") ، وبشكل أعم ، استخدم أيًا من خدماتنا (الامتداد
"الخدمات" ، والتي تشمل موقع الويب) ، نحن نقدر أنك كذلك
الوثوق بنا بمعلوماتك الشخصية. نحن نأخذ خصوصيتك جدا
بشكل جاد. في إشعار الخصوصية هذا ، نسعى لتوضيح ذلك لك في
أوضح طريقة ممكنة ما هي المعلومات التي نجمعها وكيف نستخدمها و
ما هي الحقوق التي لديك فيما يتعلق بها. نأمل أن تأخذ بعض الوقت
اقرأها بعناية ، لأنها مهمة. إذا كان هناك أي شروط
في إشعار الخصوصية هذا الذي لا توافق عليه ، يرجى التوقف
استخدام خدماتنا على الفور.
</p>
<p>
<br/>
ينطبق إشعار الخصوصية هذا على جميع المعلومات التي تم جمعها من خلال موقعنا
الخدمات (التي ، كما هو موضح أعلاه ، تشمل موقعنا الإلكتروني) ، وكذلك ،
أي خدمات أو مبيعات أو تسويق أو أحداث ذات صلة.
</p>
<p>
<br/>
يرجى قراءة إشعار الخصوصية هذا بعناية لأنه سيساعدك
فهم ما نفعله بالمعلومات التي نجمعها.
</p>
<p>
<br/>
جدول المحتويات
</p>
<p>
<br/>
1. ما هي المعلومات التي نجمعها؟
</p>
<p>
<br/>
2. هل سيتم تبادل المعلومات الخاصة بك مع أي شخص؟
</p>
<p>
<br/>
3. هل نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى؟
</p>
<p>
<br/>
4. كيف نتعامل مع تسجيلات الدخول الاجتماعية الخاصة بك؟
</p>
<p>
<br/>
5. هل يتم نقل معلوماتك دوليًا؟
</p>
<p>
<br/>
6. ما هي مدة احتفاظنا بمعلوماتك؟
</p>
<p>
<br/>
7. كيف نحافظ على أمان معلوماتك؟
</p>
<p>
<br/>
8. هل نجمع المعلومات من القصر؟
</p>
<p>
<br/>
9. ما هي حقوق الخصوصية الخاصة بك؟
</p>
<p>
<br/>
10. ضوابط ميزات "عدم التعقب"
</p>
<p>
<br/>
11. هل يتمتع سكان كاليفورنيا بحقوق خصوصية محددة؟
</p>
<p>
<br/>
12. هل نجري تحديثات على هذا الإشعار؟
</p>
<p>
<br/>
13. كيف يمكنك الاتصال بنا بخصوص هذا الإشعار؟
</p>
<p>
<br/>
14. كيف يمكنك مراجعة أو تحديث أو حذف البيانات التي نجمعها منك؟
</p>
<p>
<br/>
1. ما هي المعلومات التي نجمعها؟
</p>
<p>
المعلومات الشخصية التي تكشفها لنا
</p>
<p>
باختصار: نحن نجمع المعلومات الشخصية التي تزودنا بها.
</p>
<p>
نقوم بجمع المعلومات الشخصية التي تقدمها لنا طواعية عندما
تقوم بالتسجيل على الموقع الإلكتروني ، وتعبر عن اهتمامك بالحصول عليها
معلومات عنا أو عن منتجاتنا وخدماتنا ، عندما تشارك
في الأنشطة على الموقع (مثل نشر الرسائل على موقعنا على الإنترنت
المنتديات أو الدخول في مسابقات أو مسابقات أو هدايا) أو غير ذلك
عند الاتصال بنا.
</p>
<p>
تعتمد المعلومات الشخصية التي نجمعها على سياق بياناتك
التفاعلات معنا ومع الموقع الإلكتروني ، والاختيارات التي تقوم بها و
المنتجات والميزات التي تستخدمها. المعلومات الشخصية التي نجمعها قد
تشمل ما يلي:
</p>
<p>
بيانات الدفع. قد نقوم بجمع البيانات اللازمة لمعالجة الدفع الخاص بك إذا
إجراء عمليات شراء ، مثل رقم وسيلة الدفع الخاصة بك (مثل a
رقم بطاقة الائتمان) ، ورمز الأمان المرتبط بدفعتك
أداة. يتم تخزين جميع بيانات الدفع بواسطة PayBilla. قد تجد
رابط (روابط) إشعار الخصوصية هنا: __________.
</p>
<p>
بيانات تسجيل الدخول إلى وسائل التواصل الاجتماعي. قد نوفر لك خيار التسجيل
معنا باستخدام تفاصيل حساب الوسائط الاجتماعية الحالي الخاص بك ، مثل
Facebook أو Twitter أو حساب وسائط اجتماعية أخرى. إذا اخترت
التسجيل بهذه الطريقة ، سنقوم بجمع المعلومات الموضحة في
قسم بعنوان "كيف نتعامل مع تسجيلات الدخول الاجتماعية الخاصة بك؟" أقل.
</p>
<p>
يجب أن تكون جميع المعلومات الشخصية التي تقدمها لنا صحيحة وكاملة
ودقيقة ، ويجب عليك إخطارنا بأي تغييرات تطرأ على هذه الشخصية
معلومة.
</p>
<p>
<br/>
يتم جمع المعلومات تلقائيًا
</p>
<p>
باختصار: بعض المعلومات - مثل بروتوكول الإنترنت (IP) الخاص بك
العنوان و / أو خصائص المتصفح والجهاز - يتم جمعها
تلقائيًا عند زيارة موقعنا.
</p>
<p>
نقوم تلقائيًا بجمع معلومات معينة عند زيارتك أو استخدامك أو
تصفح الموقع. هذه المعلومات لا تكشف عن خصوصيتك
الهوية (مثل اسمك أو معلومات الاتصال) ولكنها قد تتضمن الجهاز
ومعلومات الاستخدام ، مثل عنوان IP والمتصفح والجهاز
الخصائص ونظام التشغيل وتفضيلات اللغة والإشارة
عناوين URL واسم الجهاز والبلد والموقع ومعلومات حول كيف ومتى
تستخدم موقعنا الإلكتروني والمعلومات الفنية الأخرى. هذه المعلومة
هناك حاجة في المقام الأول للحفاظ على أمن وتشغيل
الموقع ، ولأغراض التحليلات الداخلية وإعداد التقارير.
</p>
<p>
مثل العديد من الشركات ، نقوم أيضًا بجمع المعلومات من خلال ملفات تعريف الارتباط و
تقنيات مماثلة.
</p>
<p>
<br/>
المعلومات التي تم جمعها من مصادر أخرى
</p>
<p>
باختصار: قد نقوم بجمع بيانات محدودة من قواعد البيانات العامة والتسويق
الشركاء ومنصات التواصل الاجتماعي ومصادر خارجية أخرى.
</p>
<p>
من أجل تعزيز قدرتنا على تقديم عروض تسويقية ذات صلة
والخدمات لك وتحديث سجلاتنا ، قد نحصل على معلومات
عنك من مصادر أخرى ، مثل قواعد البيانات العامة والتسويق المشترك
الشركاء ، والبرامج التابعة ، ومزودو البيانات ، ومنصات الوسائط الاجتماعية ،
وكذلك من أطراف ثالثة أخرى. تتضمن هذه المعلومات المراسلات
العناوين والمسميات الوظيفية وعناوين البريد الإلكتروني وأرقام الهواتف وبيانات النية (أو
بيانات سلوك المستخدم) ، عناوين بروتوكول الإنترنت (IP) ، وسائل التواصل الاجتماعي
الملفات الشخصية وعناوين URL الخاصة بالوسائط الاجتماعية والملفات الشخصية المخصصة لأغراض
الإعلان المستهدف والترويج للحدث. إذا كنت تتفاعل معنا على أ
منصة وسائل التواصل الاجتماعي باستخدام حساب الوسائط الاجتماعية الخاص بك (مثل Facebook أو
Twitter) ، نتلقى معلومات شخصية عنك مثل اسمك ،
عنوان البريد الإلكتروني والجنس. أي معلومات شخصية نجمعها
من حساب الوسائط الاجتماعية الخاص بك يعتمد على حسابات وسائل التواصل الاجتماعي الخاصة بك
إعدادات الخصوصية.
</p>
<p>
<br/>
2. هل سيتم تبادل المعلومات الخاصة بك مع أي شخص؟
</p>
<p>
باختصار: نحن نشارك المعلومات فقط بموافقتك ، للامتثال لها
القوانين ، لتزويدك بالخدمات ، لحماية حقوقك ، أو
الوفاء بالتزامات العمل.
</p>
<p>
قد نعالج أو نشارك بياناتك التي نحتفظ بها بناءً على ما يلي
الأساس القانوني:
</p>
<p>
الموافقة: قد نعالج بياناتك إذا منحتنا موافقة محددة
لاستخدام معلوماتك الشخصية لغرض معين.
</p>
<p>
المصالح المشروعة: قد نعالج بياناتك عندما تكون معقولة
ضرورية لتحقيق مصالحنا التجارية المشروعة.
</p>
<p>
أداء العقد: حيث أبرمنا عقدًا مع
أنت ، قد نعالج معلوماتك الشخصية للوفاء بشروط
عقدنا.
</p>
<p>
الالتزامات القانونية: قد نكشف معلوماتك أينما كنا
مطلوب قانونًا للقيام بذلك من أجل الامتثال للقانون المعمول به ،
طلبات حكومية أو إجراء قضائي أو أمر محكمة أو قانوني
العملية ، مثل الرد على أمر محكمة أو أمر استدعاء (بما في ذلك
استجابة للسلطات العامة للوفاء بالأمن القومي أو القانون
متطلبات الإنفاذ).
</p>
<p>
الاهتمامات الحيوية: قد نكشف عن معلوماتك حيثما نعتقد ذلك
ضروري للتحقيق أو منع أو اتخاذ إجراء بشأن
الانتهاكات المحتملة لسياساتنا ، والاحتيال المشتبه به ، والمواقف
تنطوي على تهديدات محتملة لسلامة أي شخص وغير قانونية
الأنشطة ، أو كدليل في التقاضي الذي نشارك فيه.
</p>
<p>
وبشكل أكثر تحديدًا ، قد نحتاج إلى معالجة بياناتك أو مشاركة
المعلومات الشخصية في الحالات التالية:
</p>
<p>
تحويلات الأعمال. قد نشارك أو ننقل معلوماتك في
الاتصال بأي عملية اندماج أو بيع للشركة أو أثناء التفاوض عليها
الأصول أو التمويل أو الاستحواذ على كل أو جزء من أعمالنا
لشركة أخرى.
</p>
<p>
واجهات برمجة تطبيقات Google Maps Platform. قد نشارك معلوماتك مع بعض
واجهات برمجة تطبيقات Google Maps Platform (على سبيل المثال ، Google Maps API ، Place API). لايجاد
لمعرفة المزيد حول سياسة خصوصية Google ، يرجى الرجوع إلى هذا الرابط. نحن
استخدام بعض واجهات برمجة تطبيقات Google Maps Platform لاسترداد معلومات معينة
عند تقديم طلبات خاصة بالموقع. وهذا يشمل: وغيرها
معلومات مماثلة. يمكن أن تكون القائمة الكاملة لما نستخدم المعلومات من أجله
وجدت في هذا القسم. نحصل على جهازك ونخزنه على جهازك ("ذاكرة التخزين المؤقت")
موقعك لمدة __________ شهرًا. يمكنك إلغاء موافقتك
في أي وقت عن طريق الاتصال بنا على تفاصيل الاتصال المقدمة في نهاية
هذا المستند. Google Maps Platform APIs التي نستخدمها في المتجر و
الوصول إلى ملفات تعريف الارتباط والمعلومات الأخرى الموجودة على أجهزتك. إذا كنت مستخدم
حاليًا في المنطقة الاقتصادية الأوروبية (دول الاتحاد الأوروبي ، أيسلندا ،
ليختنشتاين والنرويج) أو المملكة المتحدة ، يرجى إلقاء نظرة على موقعنا
إشعار ملفات تعريف الارتباط.
</p>
<p>
الشركات التابعة. قد نشارك معلوماتك مع الشركات التابعة لنا ، في أي
في هذه الحالة ، سنطلب من تلك الشركات التابعة احترام إشعار الخصوصية هذا.
تشمل الشركات التابعة شركتنا الأم وأي شركات فرعية مشتركة
شركاء المشروع أو الشركات الأخرى التي نتحكم فيها أو التي تخضع لها
السيطرة المشتركة معنا.
</p>
<p>
شركاء العمل. قد نشارك معلوماتك مع أعمالنا
شركاء ليقدموا لك منتجات أو خدمات أو عروض ترويجية معينة.
</p>
<p>
مستخدمون آخرون. عند مشاركة المعلومات الشخصية (على سبيل المثال ، بواسطة
نشر التعليقات أو المساهمات أو أي محتوى آخر على الموقع) أو
تتفاعل بخلاف ذلك مع المناطق العامة في الموقع ، مثل هذه الشخصية
يمكن لجميع المستخدمين الاطلاع على المعلومات ويمكن نشرها للجمهور
متاح خارج الموقع إلى الأبد. إذا كنت تتفاعل مع الآخرين
مستخدمو موقعنا الإلكتروني والتسجيل في موقعنا من خلال مواقع التواصل الاجتماعي
شبكة (مثل Facebook) ، ستفعل جهات الاتصال الخاصة بك على الشبكة الاجتماعية
رؤية اسمك وصورة ملفك الشخصي وأوصاف نشاطك.
وبالمثل ، سيتمكن المستخدمون الآخرون من عرض أوصاف ملف
النشاط ، والتواصل معك من خلال موقعنا ، وعرض
الملف الشخصي.
</p>
<p>
<br/>
3. هل نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى؟
</p>
<p>
باختصار: قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع الأخرى لجمعها
وتخزين المعلومات الخاصة بك.
</p>
<p>
قد نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة (مثل إشارات الويب
والبكسل) للوصول إلى المعلومات أو تخزينها. معلومات محددة عن
كيف نستخدم هذه التقنيات وكيف يمكنك رفض بعض ملفات تعريف الارتباط هي
المنصوص عليها في إشعار ملفات تعريف الارتباط الخاص بنا.
</p>
<p>
<br/>
4. كيف نتعامل مع تسجيلات الدخول الاجتماعية الخاصة بك؟
</p>
<p>
باختصار: إذا اخترت التسجيل أو تسجيل الدخول إلى خدماتنا باستخدام ملف
حساب الوسائط الاجتماعية ، قد نتمكن من الوصول إلى معلومات معينة حول
أنت.
</p>
<p>
يوفر لك موقعنا الإلكتروني القدرة على التسجيل وتسجيل الدخول باستخدام
تفاصيل حساب وسائل التواصل الاجتماعي الخاصة بطرف ثالث (مثل Facebook أو Twitter
تسجيلات الدخول). عندما تختار القيام بذلك ، سوف نتلقى ملف تعريف معين
معلومات عنك من مزود وسائل التواصل الاجتماعي الخاص بك. الملف الشخصي
قد تختلف المعلومات التي نتلقاها اعتمادًا على مزود وسائل التواصل الاجتماعي
المعنية ، ولكن غالبًا ما تتضمن اسمك وعنوان بريدك الإلكتروني وأصدقائك
القائمة وصورة الملف الشخصي بالإضافة إلى المعلومات الأخرى التي تختارها
للجمهور على منصة التواصل الاجتماعي هذه.
</p>
<p>
سوف نستخدم المعلومات التي نتلقاها فقط للأغراض المحددة
الموضحة في إشعار الخصوصية هذا أو التي تم توضيحها بطريقة أخرى
أنت على موقع الويب ذي الصلة. يرجى ملاحظة أننا لا نتحكم ، و
ليست مسؤولة عن الاستخدامات الأخرى لمعلوماتك الشخصية بواسطة
مزود وسائل التواصل الاجتماعي التابع لجهة خارجية. نوصي بأن تقوم بمراجعة
إشعار الخصوصية الخاص بهم لفهم كيفية جمعهم واستخدامهم ومشاركة ملفات
المعلومات الشخصية ، وكيف يمكنك تعيين تفضيلات الخصوصية الخاصة بك على
مواقعهم وتطبيقاتهم.
</p>
<p>
<br/>
5. هل يتم نقل معلوماتك دوليًا؟
</p>
<p>
باختصار: قد نقوم بنقل معلوماتك وتخزينها ومعالجتها بتنسيق
دول غير دولتك.
</p>
<p>
تقع خوادمنا في. إذا كنت تدخل إلى موقعنا على الويب من
في الخارج ، يرجى العلم أنه قد يتم نقل معلوماتك إلى ،
يتم تخزينها ومعالجتها من قبلنا في منشآتنا ومن قبل هؤلاء الآخرين
الأطراف التي قد نشارك معها معلوماتك الشخصية (انظر "سوف
يتم تبادل المعلومات الخاصة بك مع أي شخص؟ "أعلاه) ، في وغيرها
الدول.
</p>
<p>
إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية (EEA) أو الولايات المتحدة
المملكة (المملكة المتحدة) ، فقد لا يكون لدى هذه الدول بالضرورة بيانات
قوانين الحماية أو قوانين أخرى مماثلة شاملة لتلك الموجودة في
بلد. ومع ذلك ، سوف نتخذ جميع التدابير اللازمة لحمايتك
المعلومات الشخصية وفقًا لإشعار الخصوصية هذا و
القانون الواجب التطبيق.
</p>
<p>
<br/>
6. ما هي مدة احتفاظنا بمعلوماتك؟
</p>
<p>
باختصار: نحتفظ بمعلوماتك طالما كان ذلك ضروريًا للوفاء بها
الأغراض الموضحة في إشعار الخصوصية هذا ما لم يُطلب خلاف ذلك
بموجب القانون.
</p>
<p>
سنحتفظ بمعلوماتك الشخصية فقط طالما كانت كذلك
ضروريًا للأغراض المنصوص عليها في إشعار الخصوصية هذا ، ما لم أ
مطلوب فترة احتفاظ أطول أو يسمح بها القانون (مثل الضرائب ،
المحاسبة أو المتطلبات القانونية الأخرى). لا يوجد غرض في هذا الإشعار سوف
تتطلب منا الاحتفاظ بمعلوماتك الشخصية لفترة أطول من.
</p>
<p>
عندما لا يكون لدينا عمل شرعي مستمر ، نحتاج إلى معالجة ملفات
المعلومات الشخصية ، سنقوم إما بحذفها أو إخفاء هويتها
المعلومات ، أو ، إذا لم يكن ذلك ممكنًا (على سبيل المثال ، لأنك
تم تخزين المعلومات الشخصية في أرشيفات النسخ الاحتياطي) ، ثم سنقوم بذلك
تخزين معلوماتك الشخصية بأمان وعزلها عن أي
مزيد من المعالجة حتى الحذف ممكن.
</p>
<p>
<br/>
7. كيف نحافظ على أمان معلوماتك؟
</p>
<p>
باختصار: نحن نهدف إلى حماية معلوماتك الشخصية من خلال نظام
من التدابير الأمنية التنظيمية والفنية.
</p>
<p>
لقد قمنا بتنفيذ الأمان التقني والتنظيمي المناسب
تدابير مصممة لحماية أمن أي معلومات شخصية
نحن نعالج. ومع ذلك ، على الرغم من ضماناتنا وجهودنا لتأمين
المعلومات ، لا يوجد نقل إلكتروني عبر الإنترنت أو
يمكن ضمان أن تكون تقنية تخزين المعلومات آمنة بنسبة 100٪ ، لذلك
لا يمكننا أن نعد أو نضمن أن المتسللين أو مجرمي الإنترنت أو غيرهم
الأطراف الثالثة غير المصرح لها لن تكون قادرة على هزيمة أمننا ، و
جمع معلوماتك أو الوصول إليها أو سرقتها أو تعديلها بشكل غير صحيح. رغم
سنبذل قصارى جهدنا لحماية معلوماتك الشخصية ونقلها
من المعلومات الشخصية من وإلى موقعنا على مسؤوليتك الخاصة.
يجب عليك الوصول إلى موقع الويب فقط في بيئة آمنة.
</p>
<p>
        <br/>
        8. هل نجمع المعلومات من القصر؟
    </p>
    <p>
        باختصار: نحن لا نجمع البيانات عن قصد من الأطفال أو نسوقها لهم
        أقل من 18 عامًا.
    </p>
    <p>
        نحن لا نطلب عن عمد بيانات من الأطفال دون سن 18 عامًا أو نسوقهم لهم
        سنوات من العمر. باستخدام موقع الويب ، فإنك تقر بأنك على الأقل
        18 أو أنك أحد الوالدين أو الوصي على هذا القاصر والموافقة
        على استخدام هذا القاصر المعال للموقع. إذا تعلمنا ذلك بشكل شخصي
        تم جمع المعلومات من المستخدمين الذين تقل أعمارهم عن 18 عامًا ، نحن
        سيتم إلغاء تنشيط الحساب واتخاذ الإجراءات المعقولة على الفور
        حذف هذه البيانات من سجلاتنا. إذا علمت بأي بيانات نحن
ربما تم جمعها من الأطفال دون سن 18 عامًا ، يرجى الاتصال بنا على <a href="mailto:hi@bondai.io"> hi@bondai.io </a>.
    </p>
    <p>
        <br/>
        9. ما هي حقوق الخصوصية الخاصة بك؟
    </p>
    <p>
        باختصار: في بعض المناطق ، مثل المنطقة الاقتصادية الأوروبية (EEA) و
        المملكة المتحدة (المملكة المتحدة) ، لديك حقوق تتيح لك وصولاً أكبر إلى
        والتحكم في معلوماتك الشخصية. يمكنك مراجعة أو تغيير أو
        إنهاء حسابك في أي وقت.
    </p>
    <p>
        في بعض المناطق (مثل المنطقة الاقتصادية الأوروبية والمملكة المتحدة) ، تتمتع بحقوق معينة بموجب
        قوانين حماية البيانات المعمول بها. قد تشمل هذه الحق (ط) في
        طلب الوصول والحصول على نسخة من معلوماتك الشخصية ، (2) إلى
        طلب التصحيح أو المحو ؛ (ثالثا) لتقييد معالجة
        معلوماتك الشخصية؛ و (4) إذا أمكن ، لإمكانية نقل البيانات.
        في ظروف معينة ، قد يكون لك أيضًا الحق في الاعتراض على
        معالجة معلوماتك الشخصية. لتقديم مثل هذا الطلب ، من فضلك
        استخدم تفاصيل الاتصال الواردة أدناه. سننظر ونتصرف على أساسه
        أي طلب وفقًا لقوانين حماية البيانات المعمول بها.
    </p>
    <p>
        إذا كنا نعتمد على موافقتك لمعالجة معلوماتك الشخصية ،
        لديك الحق في سحب موافقتك في أي وقت. يرجى الملاحظة
        ومع ذلك ، فإن هذا لن يؤثر على قانونية المعالجة
        قبل انسحابه ، ولن يؤثر ذلك على معالجة ملف
        المعلومات الشخصية التي أجريت بالاعتماد على أسس المعالجة القانونية
        بخلاف الموافقة.
    </p>
    <p>
        إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية أو المملكة المتحدة وتعتقد أننا كذلك
        معالجة معلوماتك الشخصية بشكل غير قانوني ، لديك أيضًا
        الحق في تقديم شكوى إلى السلطة الإشرافية على حماية البيانات المحلية.
        يمكنك العثور على تفاصيل الاتصال الخاصة بهم هنا:
        <أ
            href = "http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
    </p>
    <p>
        إذا كنت مقيماً في سويسرا ، تفاصيل الاتصال الخاصة بالبيانات
        سلطات الحماية متوفرة هنا:
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            https://www.edoeb.admin.ch/edoeb/en/home.html
        </a>
        .
    </p>
    <p>
        <br/>
        معلومات الحساب
    </p>
    <p>
        إذا كنت ترغب في أي وقت في مراجعة المعلومات أو تغييرها في
        حسابك أو إنهاء حسابك ، يمكنك:
    </p>
    <p>
        قم بتسجيل الدخول إلى إعدادات حسابك وقم بتحديث حساب المستخدم الخاص بك.
    </p>
    <p>
        اتصل بنا باستخدام معلومات الاتصال المقدمة.
    </p>
    <p>
        بناءً على طلبك لإنهاء حسابك ، سنقوم بتعطيل أو
        حذف حسابك ومعلوماتك من قواعد البيانات النشطة لدينا. لكن،
        قد نحتفظ ببعض المعلومات في ملفاتنا لمنع الاحتيال ،
        استكشاف المشكلات وإصلاحها ، والمساعدة في أي تحقيقات ، وفرض
        شروط الاستخدام و / أو الامتثال للمتطلبات القانونية المعمول بها.
    </p>
    <p>
        ملفات تعريف الارتباط والتقنيات المماثلة: تم تعيين معظم متصفحات الويب على القبول
        ملفات تعريف الارتباط بشكل افتراضي. إذا كنت تفضل ذلك ، يمكنك عادةً اختيار تعيين ملف
        متصفح لإزالة ملفات تعريف الارتباط ورفض ملفات تعريف الارتباط. إذا اخترت
        إزالة ملفات تعريف الارتباط أو رفض ملفات تعريف الارتباط ، فقد يؤثر ذلك على ميزات معينة أو
        خدمات موقعنا. لإلغاء الاشتراك في الإعلانات القائمة على الاهتمامات بواسطة
        المعلنين على موقعنا زيارة
        <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/
        </a>
        .
    </p>
    <p>
        الانسحاب من التسويق عبر البريد الإلكتروني: يمكنك إلغاء الاشتراك في التسويق لدينا
        قائمة البريد الإلكتروني في أي وقت من خلال النقر على رابط إلغاء الاشتراك في
        رسائل البريد الإلكتروني التي نرسلها أو عن طريق الاتصال بنا باستخدام التفاصيل المقدمة
        أقل. ستتم إزالتك بعد ذلك من قائمة البريد الإلكتروني التسويقي -
        ومع ذلك ، قد لا نزال نتواصل معك ، على سبيل المثال لنرسل لك
        رسائل البريد الإلكتروني المتعلقة بالخدمة والضرورية للإدارة و
        استخدام حسابك ، للرد على طلبات الخدمة ، أو لغير ذلك
        أغراض غير تسويقية. لإلغاء الاشتراك بطريقة أخرى ، يمكنك:
    </p>
    <p>
        الوصول إلى إعدادات حسابك وتحديث التفضيلات.
    </p>
    <p>
        <br/>
        10. ضوابط ميزات "عدم التعقب"
    </p>
    <p>
        معظم متصفحات الويب وبعض أنظمة تشغيل الجوال والجوال
        تتضمن التطبيقات ميزة Do-Not-Track ("DNT") أو إعداد يمكنك القيام به
        قم بتنشيطه للإشارة إلى تفضيل خصوصيتك بعدم الحصول على بيانات حول
        مراقبة أنشطة التصفح عبر الإنترنت وجمعها. في هذه المرحلة لا
        معيار تقني موحد للتعرف على DNT وتطبيقه
        تم الانتهاء من الإشارات. على هذا النحو ، لا نرد حاليًا على DNT
        إشارات المتصفح أو أي آلية أخرى تتواصل تلقائيًا
        اختيارك ألا يتم تتبعك عبر الإنترنت. إذا كان معيارًا للتتبع عبر الإنترنت
        تم اعتماده يجب أن نتبعه في المستقبل ، وسنبلغك بذلك
        هذه الممارسة في نسخة منقحة من إشعار الخصوصية هذا.
    </p>
    <p>
        <br/>
        11. هل يتمتع سكان كاليفورنيا بحقوق خصوصية محددة؟
    </p>
    <p>
        باختصار: نعم ، إذا كنت مقيمًا في كاليفورنيا ، يتم منحك
        حقوق محددة فيما يتعلق بالوصول إلى معلوماتك الشخصية.
    </p>
    <p>
        القسم 1798.83 من القانون المدني لولاية كاليفورنيا ، المعروف أيضًا باسم "Shine The
        يسمح القانون الخفيف لمستخدمينا المقيمين في كاليفورنيا بطلب ذلك
        والحصول على معلومات منا مرة واحدة في السنة مجانًا
        فئات المعلومات الشخصية (إن وجدت) التي كشفنا عنها للثالث
        الأطراف لأغراض التسويق المباشر وأسماء وعناوين
        جميع الأطراف الثالثة التي شاركنا معها المعلومات الشخصية في
        مباشرة قبل السنة التقويمية. إذا كنت مقيمًا في كاليفورنيا
        وترغب في تقديم مثل هذا الطلب ، يرجى إرسال طلبك بتنسيق
        الكتابة إلينا باستخدام معلومات الاتصال الواردة أدناه.
    </p>
    <p>
        إذا كان عمرك أقل من 18 عامًا ، فأنت مقيم في كاليفورنيا ولديك
        حساب مسجل في الموقع ، يحق لك طلب ذلك
        إزالة البيانات غير المرغوب فيها التي تنشرها علنًا على الموقع. إلى
        طلب إزالة هذه البيانات ، يرجى الاتصال بنا باستخدام جهة الاتصال
        المعلومات الواردة أدناه ، وتشمل عنوان البريد الإلكتروني المرتبط
        بحسابك وبيان أنك تقيم في كاليفورنيا. نحن
        سوف تتأكد من عدم عرض البيانات علنًا على موقع الويب ، ولكن
        يرجى العلم أن البيانات قد لا تكون كاملة أو شاملة
        إزالتها من جميع أنظمتنا (مثل النسخ الاحتياطية ، وما إلى ذلك).
    </p>
    <p>
        <br/>
        12. هل نجري تحديثات على هذا الإشعار؟
    </p>
    <p>
    باختصار: نعم ، سنقوم بتحديث هذا الإشعار حسب الضرورة للبقاء
    متوافق مع القوانين ذات الصلة.
</p>
<p>
    قد نقوم بتحديث إشعار الخصوصية هذا من وقت لآخر. المحدثة
    ستتم الإشارة إلى الإصدار من خلال التاريخ المحدث "المنقح" والتاريخ المحدث
    سيكون الإصدار ساريًا بمجرد الوصول إليه. إذا فعلنا
    التغييرات المادية في إشعار الخصوصية هذا ، قد نخطرك إما عن طريق
    نشر إشعار في مكان بارز بهذه التغييرات أو بإرساله إليك مباشرةً
    إشعار. نحن نشجعك على مراجعة إشعار الخصوصية هذا
    كثيرًا ليتم إبلاغك بكيفية حماية معلوماتك.
</p>
<p>
    <br/>
    13. كيف يمكنك الاتصال بنا بخصوص هذا الإشعار؟
</p>
<p>
    إذا كانت لديك أسئلة أو تعليقات حول هذا الإشعار ، فيمكنك مراسلتنا عبر البريد الإلكتروني
    على <a href="mailto:hi@bondai.io"> hi@bondai.io </a> أو بالبريد إلى:
</p>
<p>
    شركة بونداي
</p>
<p>
    2035 Sunset Lake RD STE B2
</p>
<p>
    نيوارك ، DE 19702-2600
</p>
<p>
    الولايات المتحدة
</p>
<p>
    <br/>
    14. كيف يمكنك مراجعة أو تحديث أو حذف البيانات التي نجمعها منك؟
</p>
</div>
`,
  },
};
