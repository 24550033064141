<template>
  <div>
    <v-menu
    v-if="!isMd"
    
    offset-y min-width="150px">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center mx-3" v-bind="attrs" v-on="on">
          <span
            class="font-14 d-inline-block mx-1"
            :class="isHomepage ? ' white--text' : ' darkblue2--text'"
            >{{
              currentCurrency == "USD"
                ? translate[`navbar.usd`]
                : translate[`navbar.saudiRiyal`]
            }}</span
          >
          <img
            class="transition-03 mt-1"
            width="12px"
            height="12px"
            :src="
              require(`@/assets/images/icons/arrow-down${
                isHomepage ? '-white' : ''
              }.svg`)
            "
          />
        </div>
      </template>

      <v-list :elevation="0">
        <v-list-item
          v-for="(currency, i) in currencies"
          :key="i"
          @click="selectCurrency(currency)"
        >
          <v-list-item-title
            class="cursor-pointer pb-1 font-14"
            :class="translate['currency.' + currency.title]"
            >{{ translate["currency." + currency.title] }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- mobile  -->

    <v-list-group v-if="isMd" no-action class="px-0">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center " v-bind="attrs" v-on="on">
              <span
                class="font-14 d-inline-block darkblue2--text"
                >{{
                  currentCurrency == "USD"
                    ? translate[`navbar.usd`]
                    : translate[`navbar.saudiRiyal`]
                }}</span
              >
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(currency, i) in currencies"
        :key="i"
        @click="selectCurrency(currency)"
        class="px-8"

      >
        <v-list-item-title
          class="cursor-pointer pb-1 font-14"
          :class="translate['currency.' + currency.title]"
          >{{ translate["currency." + currency.title] }}</v-list-item-title
        >
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { updateUserDetails } from "@/services/User";

export default {
  data: () => ({
    currencies: [
      // { title: 'chineseYuan' },
      // { title: 'dirham' },
      // { title: 'euro' },
      // { title: 'Pound' },
      // { title: 'turkishLira' },
      { title: "dollar", value: "USD" },
      { title: "saudiRiyal", value: "SAR" },
    ],
  }),
  computed: {
    ...mapGetters(["translate", "token"]),
    currentCurrency() {
      return localStorage.getItem("currency") || "SAR";
    },
    isHomepage() {
      if (this.$route.name == "Homepage") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async selectCurrency(currency) {
      localStorage.setItem("currency", currency.value);
      if (this.token) {
        let reqObj = {
          globalPreferences: {
            currencyCode: currency.value,
          },
        };
        await updateUserDetails(reqObj);

      }      
      this.$router.go();
    },
  },
};
</script>

<style lang="scss">
.v-list-item {
  min-height: 40px !important;
  height: 40px !important;
}
</style>
