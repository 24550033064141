<template>
  <div class="input-style relative" :class="type== 'number' ?'base-phone' : ''">
    <span class=" font-16 d-block mb-1" :class="isPrimary ?'primary--text' :'black--text' ">{{title}}</span>
    <v-text-field
      class="w-100"
      :type="type"
      :outlined="outlined"
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      :value="value"
      :min="0"
      :max="maxNumber"
      :max-length="counter ? maxLength : false"
      @input="$emit('input', $event)"
      :rounded="rounded"
      :prepend-inner-icon="prependIcon"
      :append-icon="customIcon ? appendIcon : type == 'email' && hasValue ? 'mdi-check' :''"
      full-width
      :readonly="readonly"
      color="black"
      @blur="$emit('blur')"
      @keypress="$emit('keypress', $event)"
      single-line
      @click:append="$emit('appendClicked')"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "text",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: ''
    },
    maxLength: {
      type: String,
      default: ''
    },
    maxNumber: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style lang="scss">
.input-style {
  .v-input__slot {
    border-radius: 4px;
    fieldset {
      height: 49px !important;
      border: 1px solid #B8BBC0 !important;
      box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        height: 49px !important;
        border: 1px solid #e44358 !important;
      }
    }
  }
}

/* Firefox */
.base-phone input[type="number"] {
}
.v-icon--link{
  margin-top: 10px
}
.v-text-field__details{
  margin-bottom: 0px !important
}
.v-input__prepend-inner{
  margin-top:10px
}
</style>
