<template>
  <div class="login-form pa-5 white">
    <div class="d-flex align-center justify-space-between mb-7">
      <h3 class="font-26 black--text">{{$t('dashboard.phoneVerification')}}</h3>
      <img
        class="login-form__close cursor-pointer"
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
      />
    </div>

    <!-- form -->
    <div class="mt-6">
      <span class="darkblue2--text font-16 d-block mb-5"
        >{{ $t('dashboard.enterCode') }}
        {{ verifyPhone.phoneNumber || verifyPhone }}</span
      >

      <div class="px-14">
        <v-otp-input v-model="code" length="6"></v-otp-input>
      </div>

      <base-button
        :title="
          remainingTime != 'done'
            ? $t(`dashboard.resendCode`) + '(' + remainingTime + ')'
            : $t(`dashboard.resendCode`)
        "
        classes="radius-5 mt-6 w-100"
        height="50px"
        :disabled="remainingTime != 'done'"
        :outlined="true"
        :loading="loadingResend"
        @click="resendCodeHandler"
        v-if="!isChangePhone"
      ></base-button>

      <base-button
        :loading="loadingSubmit"
        width="100%"
        :title="isChangePhone ? $t('dashboard.updatePhone') : $t('dashboard.verifyPhone')"
        classes="radius-5  mt-4"
        @click="submitHandler"
        height="50px"
        color="primary"
      ></base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sendPhoneConfirmationToVerify } from "@/services/User";
import {
  changePhoneNumber,
  sendPhoneConfirmationToUpdate,
} from "@/services/User";
export default {
  data: () => ({
    obj: {},
    loadingResend: false,
    loadingSubmit: false,
    valid: false,
    remainingTime: "done",
    code: "",
  }),
  computed: {
    ...mapGetters(["translate", "verifyPhone", "isChangePhone"]),
  },
  methods: {
    viewRemainTime() {
      let minutes, seconds;
      setInterval(() => {
        var remaining = localStorage.endTimePhone - new Date();
        minutes = parseInt(Math.floor(remaining / 1000) / 60, 10);
        seconds = parseInt(Math.floor(remaining / 1000) % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        if (remaining >= 0) {
          this.remainingTime = minutes + ":" + seconds;
        } else {
          this.remainingTime = "done";
          clearInterval();
        }
      }, 100);
    },
    async resendCodeHandler() {
      this.loadingResend = true
      if (this.remainingTime != "done") return;
      this.loadingResend = false

      await sendPhoneConfirmationToVerify({
        languageCode: localStorage.getItem("language"),
      });
      if (this.remainingTime == "done") {
        localStorage.endTimePhone = +new Date() + 5 * 60 * 1000;
      }
      this.viewRemainTime();
    },
    async submitHandler() {
      this.loadingSubmit = true;

      if (this.isChangePhone) {
        let res = await changePhoneNumber({
          phoneNumber: {
            regionCode: this.verifyPhone.regionCode,
            phoneNumber: this.verifyPhone.phoneNumber,
          },
          token: this.code,
        });
        this.loadingSubmit = false;

        if (res.data) {
          this.$store.dispatch("showSnack", {
            text: "Phone number has been changed",
            color: "success",
          });

          this.$emit("close");
          this.$store.dispatch("reRenderUser");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.message,
            color: "error",
          });
        }
      } else {
        let res = await sendPhoneConfirmationToUpdate({
          token: this.code,
        });
        this.loadingSubmit = false;
        if (res.data) {
          this.$store.dispatch("showSnack", {
            text: "Phone number has been verified successfully",
            color: "success",
          });

          this.$emit("close");
          this.$store.dispatch("reRenderUser");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.message,
            color: "error",
          });
        }
      }
    },
  },
  created() {
    this.viewRemainTime();
  },
};
</script>

<style lang="scss">
.login-form {
  box-shadow: 0px 2px 25px 0px #0000001a;
  border-radius: 24px;
  position: relative;

  &__or {
    &__line {
      background: #dbdde0;
      width: 100%;
      height: 1px;
      margin-top: 2px;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
.img-bg {
  background: #fef9ea;
}
</style>
