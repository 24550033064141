export default {
  experienceDetails: {
    privacyPolicy:
      "I read and agree to the <span class='font-600'>Privacy Policy.</span> ",
  },
  homepage: {
    searchTitle: "Find the places you wish to visit",
    world: "World",
    kingdom: "Kingdom",
  },
  rules: {
    checkAllFields: "Please check and correct all the fields with warning",
    mustTrue: "Terms must be approved",
    locationRequired: "One location at least is required",
  },
  auth: {
    length: "Must be at least 6 characters.",
    containUppercase: "Must contain one Uppercase Character least.",
    containLowercase: "Must contain one  Lowercase Character least.",
    oneSymbol: "Must contain one Symbol at least.",
    containNumbers: "Must contain one Number at least.",
  },
  blogs: {
    bookYourTrip: "Book your trip",
  },

  filter: {
    priceInterval: "Price Interval",
    selectPrice: "Select Price",
    selectType: "Select Type",
    selectDifficulty: "Select Difficulty",
    selectCountryCode: "Select Country",
    sortByPrice: "Sort by Price",
  },
  footer: {
    copyRight1: "Bondai © ",
    copyRight2: " All Rights Reserved",
    registerNumber: "VAT Registration Number #",
    eCR: "eCR #",
    ksa: "KSA Ministry of Tourism license number",
  },
  global: {
    english: "English",
    arabic: "العربية",
    chinese: "中国人",
    day: "Day",
    bondaiGroup: "Bondai Group",
    reviews: "reviews",
    save: "Save",
    activityDetails: "Activity Details",
    person: "Person",
    perPerson: "Per Person",
    from: "From",
    cookiesTitle: "We value your privacy",
    cookiesSubTitle:
      'We use cokies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "I Understand", you consent to our use of cookies.',
    understand: "I Understand",
    readMore: "Read More",
    homepage: "Homepage",
    experiences: "Experiences",
    age: "age",
    please: "Please",
    clickHere: "Click Here",
    toSendVerify: "to send verify link to your email",
    gender: "Gender",
    title: 'Title',
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    very: "Very Easy",
    easy: "Easy",
    moderate: "Moderate",
    challenging: "Challenging",
    extreme: "Extreme",

  },

  dashboard: {
    changePlan: "Change of Plan",
    conventionCancelled: "Convention Cancelled",
    duplicateBooking: "Duplicate Booking",
    flightCancelled: "Flight Cancelled",
    changeBusiness: "Change of Business Event Place or Date",
    deathFamily: "Death in Family",
    other: "Other",
    enterCode: 'Please enter the 6-digit code sent to',
    phoneVerification: 'Phone Number Verification',
    resendCode: 'Resend Code',
    updatePhone: 'Update Phone',
    verifyPhone: 'Verify',
    newPhoneNumber: 'New Phone Number',
    verified: 'Verified'
  },

  cancellation: {
    fullPart1:
      "Free Cancellation:You can cancel your reservation free of charge until",
    fullPart2:
      "The full amount you paid will be refunded to your payment method.",

    halfPart1:
      "Partial penalty cancellation: In accordance with the cancellation policy, a",
    halfPart2:
      "penalty will be charged from the amount you paid for your reservation. The remaining",
    halfPart3: "will be refunded to your payment method.",

    none: "Non-refundable: If you cancel your reservation, no refund will be given in accordance with the cancellation policy.",
    customReason: "Custom Reason",
  },

  messages: {
    newExperienceAdded: "New experience added succesfully",
    subjectForm: "Subject form has been sent successfully",
    messageSent: "Message has been sent successfully",
    wrong: "Error, please try again later!",
  },

  static: {
    termsTitle: "Terms Policy",
    terms: `
    
    <p><strong>Terms of Use</strong></p>
<p><br></p>
<p>Welcome to Bondai! These Terms of Use along with the Privacy Policy constitutes a legally binding agreement governing your use of Bondai&rsquo;s website/application <a data-fr-linked="true" href="//www.bondai.io">www.bondai.io</a> and all the legal effects that result from your use of the provided services, any use of the services of Bondai&rsquo;s website/application is considered an acceptance of these terms and conditions. Therefore, these Terms of Use are valid and enforceable once you register or use Bondai&rsquo;s website/application. We may change the Terms of Use from time to time, and we advise you to review them periodically to know what changes have occurred.</p>
<p><br></p>
<p>Your use of Bondai&rsquo;s website/application is conditioned on your acceptance of the terms and conditions of these Terms of Use. If you do not agree with any part of the Terms of Use, you must stop using the website/application and delete your account completely. As your continued use of the website/application is an acknowledgment from you that you have read and understood the terms and conditions of this page and accepted them. In the event that there is any incomprehensible part of this page or any service provided on Bondai&rsquo;s website/application, please contact us.</p>
<p><br></p>
<p><strong>Definitions</strong></p>
<p><br></p>
<p><strong>Platform/We/Bondai</strong>: This term means Bondi Company for Travel and Tourism, Commercial Register (4030480178), and this definition includes all forms of the company, whether it is a website or an electronic application.</p>
<p><strong>User/Client/You</strong>: This term means the person who uses, visits, registers with, or requests any service through the Platform, and who must abide by these Terms of Use. It should also be noted that the word &ldquo;you&rdquo; may refer to more than one person.</p>
<p><strong>Service Provider</strong>: This term means the company, institution, individuals, or subsidiaries who provide services through the Platform, whether they are contracting parties or not.</p>
<p><strong>Service(s)</strong>: This term means all the services provided on the Platform related to travel and tourism activities, accommodation, transfers, experiences, and any other activities provided by the Service Provider to the Client through the Platform.</p>
<p><strong>Agreement</strong>: This term means these Terms of Use which include the terms and conditions and Privacy Policy of the Platform and any written, electronic or oral agreements or contracts made between the Platform and the User for the purpose of providing the Services.</p>
<p><strong>Laws</strong>: This term means the laws in force in the Kingdom of Saudi Arabia.</p>
<p><br></p>
<p><strong>Account Terms</strong></p>
<ul >
    <li>To be able to register on the Platform, you must be of full legal capacity in accordance with the regulations in force in the Kingdom of Saudi Arabia.<br><br></li>
    <li>Your account&rsquo;s name must be consistent with your official data, and it is prohibited to register under false, misleading, irregular or third party&rsquo;s names.<br><br></li>
    <li>You must register for an account on the Platform with your mobile number and e-mail, in order to be able to receive any notifications sent to you from the Platform.<br><br></li>
    <li>You are prohibited from registering on the Platform with more than one account, and all accounts will be deleted if it is found that there is more than one account.<br><br></li>
    <li>You must maintain your username and password, and not disclose it to others. In all cases you are committed to all transactions which take place through your account on the Platform.&nbsp;<br><br></li>
    <li>In the event that you do not comply with any of the terms of this clause, these terms will be considered null and void, with the application of all legal effects arising during its validity period.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>The Accuracy of Your Information</strong></p>
<p>You acknowledge that all your data and information on the Platform are true, correct, updated, complete and legal. You are obligated to update your information in the event of any change, and the Platform is not responsible for any incorrect information provided by you.</p>
<p><br></p>
<p><br></p>
<p><strong>Providing the Services</strong></p>
<ul>
    <li>The Platform technically connects the Service Providers with the Client, in order to facilitate the providing of the services to its seekers; therefore, although the Platform is keen to contract with the best Service Providers, it is not responsible for the quality of Services provided by the Service Provider, and the Service Provider is solely responsible for that.<br><br></li>
    <li>The Platform is keen to provide the best prices compared to other websites, and the Platform does not impose any administrative fees on the Client, but rather all its administrative fees are deducted from the Service Provider directly, unless it is mentioned otherwise.<br><br></li>
    <li>Promotional offers vary by Service Provider; therefore, offers may not apply to all Services.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>1) Accommodation Services</strong></p>
<p>1. The Platform provides the opportunity for the Service Providers to promote their accommodations, whether hotels, apartments, chalets or cottages, by leasing them for certain periods, and thus the Platform enables the Client to search for the best accommodations and allows it to compare them with other offers before completing the checkout process.</p>
<p>2. The Client chooses, through the Platform, the accommodation Services he/she wishes to book, selects the required date, and then completes the booking process.</p>
<p>3. The booking made by the Client on the platform when choosing accommodation Services are made directly and automatically with the Service Provider, and the Platform is not a contractual party to it.</p>
<p>4. The Client is obligated to fill in the booking details properly, clearly and correctly so that the Platform and the Service Provider can communicate with the Client and provide him/her with the booking information.</p>
<p>5. Additional information and Services provided by the Service Provider such as: facilities, accommodation instructions, prices, available dates, and cancellation policy will be made clear to the Client before completing the booking process.</p>
<p>6. Before completing the booking process, the Client acknowledges that he/she has read the instructions of the Service Provider carefully and that he/she agrees with them, and that violating them may lead to the imposition of additional fees or cancellation of the booking.</p>
<p>7. After completing the booking process and paying the fees, the Client will receive an e-mail confirming the booking, and if the Client does not receive the confirmation message, he/she must contact the Platform.</p>
<p>8. Changing or cancelling a booking depends on the cancellation policy for the booking provided by the Service Provider, and it varies according to the Service Provider. Therefore, we recommend reviewing the information and cancellation policy before completing the booking process.</p>
<p>9. The Client is obligated to preserve the residence upon his/her arrival, and what is contained in the residence. the Client is obligated to leave it in the same condition it was when he/she received it, and the compensation policy of the Service Provider applies.</p>
<p>10. The Service Provider may refuse to modify or cancel any of the bookings according to its cancellation policy. In all cases, the Platform is not responsible for that, as its role is limited to the technical connection between the Client and the Service Provider, and the Client must communicate directly with the Service Provider.</p>
<p><br></p>
<p><strong>2) Trips and Activities</strong></p>
<ul>
    <li>The role of the Platform is limited to providing technical connection services between the Client and the Service Provider, and by doing so, it provides the opportunity for the Service Providers to promote their trips and activities and enables the Client to search for them and compare them with other Service Providers&rsquo; offers before completing the booking process.<br><br></li>
    <li>The Platform does not provide any trips or entertainment activities directly, nor is it responsible for organizing them. Therefore, booking an appointment for any of the trips or entertainment activities on the Platform enters you into a direct contractual relationship with the Service Provider, through which you are subject to the terms and conditions of the Service Provider.<br><br></li>
    <li>The Client, through the Platform, chooses the trips and entertainment activities he/she wishes to book, selects the required date, and then completes the booking process.<br><br></li>
    <li>The Client is obligated to fill in the booking details properly, clearly, and correctly so that the Platform or the Service Provider can communicate with him/her and provide him/her with the booking information.<br><br></li>
    <li>Before completing the booking process, the Client acknowledges that he/she has read the instructions of the Service Provider carefully and that he/she agrees with them, and that violating them may lead to the imposition of additional fees or cancellation of the booking.<br><br></li>
    <li>After completing the booking process and paying the fees, the Client will receive an e-mail confirming the booking, and if the Client does not receive the confirmation message, he/she must contact the Platform.<br><br></li>
    <li>Changing or cancelling a booking depends on the booking cancellation policy provided by the Service Provider, and it varies according to the Service Provider. Therefore, we recommend reviewing the information and cancellation policy described before completing the booking process.<br><br></li>
    <li>The Service Provider may refuse to modify or cancel any of the bookings according to its cancellation policy. In all cases, the Platform is not responsible for that, as its role is limited to the technical connection between the Client and the Service Provider, and the Client must communicate directly with the Service Provider.</li>
</ul>
<p><br></p>
<p><strong>3) Aviation Services</strong></p>
<p>1. All flights offered on the Platform are provided by a Service Provider acting as an intermediary for the airlines; Therefore, all airline bookings made by the Client are made by the airline, and the Client enters into two contractual relationships; The first is with the airline mediator (Service Provider) and the second is with the airline providing the flight, and the Platform is not an party to any of them.</p>
<p>2. The Client, through the Platform, chooses the flights he/she wishes to book, selects the required date, and then completes the booking process.</p>
<p>3. The Client is obligated to fill in the booking details properly, clearly and correctly so that the Platform, the Service Provider or the airline company can communicate with him/her and provide him/her with the booking information.</p>
<p>4. Before completing the booking process, the Client acknowledges that he/she has read the instructions of the Service Provider carefully and that he/she agrees with them, and that violating them may lead to the imposition of additional fees or cancellation of the booking.</p>
<p>5. After completing the booking process and paying the fees, the Client will receive an e-mail confirming the booking, and if the Client does not receive the confirmation message, he/she must contact the Platform.</p>
<p>6. Changing or cancelling your booking depends on the cancellation policy of your booking, as it differs according to the Service Provider. Therefore, we recommend that you review the information and cancellation policy before completing the booking.</p>
<p>7. The airline may reschedule or cancel flights at its discretion, and this does not fall within the responsibility of the Platform, and the Client can communicate directly with the airline.</p>
<p>8. Any additional Services the Client wishes to request, he/she must communicate directly with the airline and request the Services.</p>
<p>9. In the event that the Client booked a one-way ticket without a return booking, he/she may need to prove that he/she has a return flight with the airline staff.</p>
<p>10. The Client may need a travel visa for the destination to which he/she booked; Therefore, the Client must obtain this visa him/herself, and we recommend making sure that the passport is valid for the period of travel and that its expiration date is approved by all countries that the Client intends to enter. For more information about passport and visa requirements, the Client must contact the embassy of the country he/she is going to visit.</p>
<p>11. Any additional fees imposed by the country or the airport on the Client are not included in the booking fees and are collected directly at the airport, so the Client must check if there are additional fees.</p>
<p>12. In all cases, the Platform is not responsible for any requirements or warnings related to travel, nor for any damages or losses incurred as a result of the Client&apos;s lack of knowledge of these requirements or failure to meet them.</p>
<p><br></p>
<p><strong>4) Private transportation</strong></p>
<p>1. The bookings made by the Client on the Platform when choosing private transportation services are made directly with the Service Provider, and the Platform is not a contractual party to it.</p>
<p>2. The Client is obligated to fill in the booking details properly, clearly and correctly so that the Platform or the Service Provider can communicate with him/her and provide him/her with the booking information.</p>
<p>3. Before completing the booking process, the Client acknowledges that he/she has read the instructions of the Service Provider carefully and that he/she agrees with them, and that violating them may lead to the imposition of additional fees or cancellation of the booking.</p>
<p>4. After completing the booking process and paying the fees, the Client will receive an email confirming the booking, and if the Client does not receive the confirmation message, he/she must contact the Platform.</p>
<p>5. The Service Provider is responsible for arranging and providing the Client&apos;s means of transportation, choosing routes, determining prices, and providing all relevant information.</p>
<p>6. Changing or cancelling your booking depends on the cancellation policy of your booking, as it differs according to the Service Provider. Therefore, we recommend that you review the information and cancellation policy described before completing the booking.</p>
<p>7. Your booking may be cancelled for a reason related to the Service Provider, in which case the Client must communicate directly with the Service Provider.</p>
<p>8. The Client must ensure that all passengers are at the pick-up location at the specified time in order to avoid any cancellation.</p>
<p>9. The Client must ensure that the means of communication entered into the Platform are correct and that the phone is available to receive calls and text messages so that the driver can access it, and the Platform does not guarantee that the driver will be able to reach the Client through electronic communication applications such as WhatsApp and other applications.</p>
<p>10. In the event that the Client booked a transportation service from the airport, he/she must provide the Platform with his/her flight details at least 24 hours before the time of arrival, so that the Service Provider can determine the time of arrival. In the event that the flight is delayed, the Client must inform the Service Provider of this.</p>
<p>11. The Service Provider may charge the Client additional fees if the flight or its routes are changed.</p>
<p>12. The Client undertakes not to act inappropriately and not to attack the drivers of the Service Provider for any reason.</p>
<p>13. The Client is obligated not to smoke inside the car, not to cause any damage or harm to the car, and to maintain its cleanliness throughout the duration of the trip. If the Client does not comply with this, he/she will be charged a cleaning fee of 250 Saudi Riyals, which will be deducted directly from the Client.</p>
<p>14. The Client is obligated to preserve his personal property and not to leave it anywhere, as Bondi is not responsible for it and does not guarantee its safety, and it is the responsibility of the Client alone.</p>
<p><br></p>
<p><strong>The Prices</strong></p>
<ul>
    <li>The Platform makes every effort to ensure the validity and accuracy of the displayed prices, however, in the event of an error in the displayed prices, the Platform reserves the right to amend the offered prices, even if that was during the booking process.<br><br></li>
    <li>You know that the fees shown for each Service are fixed and final, and you have no right to request a reduction after checking out.<br><br></li>
    <li>You shall pay the Service fees through the payment methods available on the Platform, and you are prohibited from using incorrect or illegal payment methods, and if you breach that, you will be solely responsible.<br><br></li>
    <li>The Service Provider may request that fees be paid in advance when booking or paid upon arrival at the Service Provider&rsquo;s place. Therefore, you know that advance payments may be non-refundable according to the Service Provider&apos;s cancellation policy, which appears before the completion of the booking process, and the Platform is not responsible for that.<br><br></li>
    <li>The Service Provider may request additional fees for any other services provided by it, and the Platform is not responsible for that.<br><br></li>
    <li>The Client knows that all payments made through the Platform are transferred to the Service Provider, and that the role of the Platform in this case is to organize payments on behalf of the Service Provider.</li>
</ul>
<p><br></p>
<p><strong>Client Undertakes</strong></p>
<ul>
    <li>Upon completion of the booking process, the Client agrees to the terms and conditions, and the cancellation policies for each Service Provider and any other policies, which may include (age requirements, insurance amount, additional services, extra beds, meals provided, and allowed cards) and other conditions that the Client must view, read and confirm before completing the booking process.<br><br></li>
    <li>You must be committed to show and appear at the selected date and time according to the instructions shown for the Service you have chosen. Any delay by you may result in cancelling the booking, and in this case the Platform will not be responsible, and you will not be entitled for a refund.<br><br></li>
    <li>You must benefit from the chosen Services, and you are prohibited from letting any other persons to benefit from those services under your name. You will be fully responsible for any damages incurred as a result of breaching this clause.<br><br></li>
    <li>You undertake that all information and data provided by you are true, correct and verified, otherwise you are solely responsible for any damages that may arise as a result of the incorrectness of the information and data.<br><br></li>
    <li>You are obligated to provide the necessary assistance and facilities in the event that the Platform conducts any investigations regarding any use that we believe violates the provisions of this agreement, violates the applicable regulations, causes legal disputes for the Platform, or harms any of the users of the Platform.<br><br></li>
    <li>You are obligated not to use the Platform for illegal or unreal purposes or to make fake bookings.<br><br></li>
    <li>You must maintain the reputation of the Platform, not harm it directly or indirectly, and not cause any damages or legal claims to the Platform, and the Platform has the right to legally claim against you in case you breached this clause.<br><br></li>
    <li>You are bound by the terms and conditions of these Terms of Use, and in the event that you violate any of the provisions of these terms, the Platform has the right - without any prior warning - to delete your account, and it does not bear any compensation or obligation for that. The Platform is also entitled to claim compensation for any damages arising as a result of your breach of your obligations stipulated in this agreement.</li>
</ul>
<p><br></p>
<p><strong>Modification and Cancellation</strong></p>
<p><br></p>
<p><strong>Modification/Changes to the Booking</strong></p>
<p><br></p>
<p>&bull; When you make a booking for any of the Services provided on the Platform, you know that the Service you applied for is subject to the terms and conditions of the Service Provider and its modification and cancellation policy. Therefore, you agree to the terms and conditions and the amendment and cancellation policy stipulated under each Service.</p>
<p>&bull; The Client must read the cancellation policy for each service before completing the booking process, as the cancellation policy differs from one Service Provider to another.</p>
<p>&bull; In the event that the Client cancels the booking or does not show up at the specified time, any cancellation or no-show fees or any refund depends on the cancellation policy of the Service Provider.</p>
<p>&bull; For Services where payment is in advance, the Service Provider has the right to cancel the booking without notifying the Client if the Client fails to pay on time.</p>
<p><br></p>
<p><br></p>
<p>If the Platform Makes Changes to Your Booking</p>
<p><br></p>
<p>Sometimes it may be necessary to make changes to a Client&rsquo;s booking after it has been accepted either by us or by the Service Provider. In all cases, we will inform you of this as soon as possible before the service date, and in the event that the proposed changes are not acceptable, we will offer you a full refund. However, we will not be liable if you incurred any losses directly or indirectly as a result of these changes.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size: 20px;">Third Parties</span></strong></p>
<ul>
    <li>We contract with other parties to support the Platform and operate it optimally. For example, we contract with third party payment gateways to secure your payment process, and to provide multiple payment methods. We contract with third parties to manage website and application hosting, to store the content and the data, and to ensure the continuous and functioning of the website and the application without interruption or loss of content or data.<br><br></li>
    <li>You authorizes us to deal with the contracting parties on your behalf, and you are bound by all the contracts that we conclude with these parties and any usage restrictions, as the work of these parties has become an integral part of the work of the Platform, and if you reject to contract with these parties, it means that you do not want to take full advantage of the Platform&apos;s Services.<br><br></li>
    <li>We may change the contracting parties from time to time, for various reasons, and we may contract with new parties, and in these cases we will not seek your opinion or obtain your approval, but we will do so on our own without the need to refer back to you.<br><br></li>
    <li>Sometimes we use non-contracting third parties to assist us in providing the Services, by connecting our services with them for common purposes, such as social media websites and applications. We may provide you links to sign up using your accounts in third parties&rsquo; websites and by clicking on them you will be referred to their website. These third parties have their own policies which may differ, in whole or in part, from our policies; Therefore, you should review these policies before using the services of third parties.<br><br></li>
    <li>The Platform disclaims any legal responsibility for any errors, direct or indirect, intentional or unintentional, made by third parties that provide services through the Platform. You use the services of these non-contracting parties at your own risk.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>Our Responsibility</strong></p>
<p><br></p>
<ul>
    <li>Bondai work is limited to connecting the Service Provider with the Client, and Bondai does not provide the Services by itself; Therefore, the Platform is not responsible for the Services provided by Service Providers.<br><br></li>
    <li>The Platform is keen to choose the best Service Providers to deal with in order to ensure the quality of the Services provided, but it is not responsible in any way for any unsatisfactory or delayed Service by the Service Provider, nor for any losses or malfunctions resulting from this service or its lack of quality, or for the delay in providing it by the Service Provider.<br><br></li>
    <li>The Platform takes reasonable care to ensure the accuracy of the information provided by the Service Providers but does not assume responsibility for any error or lack of clarity in any of this information.<br><br></li>
    <li>The Platform is not responsible for any claim, dispute, damages or any liability arising directly or indirectly from the Service Provider.<br><br></li>
    <li>The Platform may stop from time to time, and it may stop permanently, whether for voluntary or involuntary reasons, and therefore you exempt us from any legal responsibility in the event of permanent or temporary cessation of the Platform or any of its services.<br><br></li>
    <li>You know that no person or entity has the right to raise a claim against to the Platform for any reason or compensation regarding the Service Provider&rsquo;s breach of any of its obligations, or in the event of its negligence, or any error in providing its Services, because the Platform has an independent legal personality and the Service Provider is solely responsible for any breach of its obligations or &nbsp;the applicable regulations.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>Access Restriction</strong></p>
<p><br></p>
<p>In the event that we find that you breached any of the obligations stipulated in these Terms of Use or violated the relevant rules and regulation, we may, without the need to notify you, stop, suspend or restrict your access to the Platform or registering in it. You have no right to claim any compensation or hold the Platform responsible for that. The Platform is also entitled to take all legal measures, and to inform the competent authorities of any violating practices by you, if required.</p>
<p><br></p>
<p><br></p>
<p><strong>Your Responsibility</strong></p>
<p>You undertake to assume full responsibility to protect the Platform from any damages that may occur to it as a result of your violation of the terms and conditions stipulated in these Terms of Use or any of the applicable regulations, and you are also obligated to prevent any damage that may occur on the Platform as a result of claims, losses or malfunctions or fees due to misuse of the Platform or violation of regulations and these terms.</p>
<p><br></p>
<p><strong>Compensation</strong></p>
<p>1. The Platform does not provide any kind of insurance or compensation to any of its users or Clients, whether for damages arising from the fault of Service Providers or from any negligence on the part of the Platform, its employees or those responsible for its operation.</p>
<p>2. The user is obligated to fully compensate the Platform for all material and moral damages arising from illegal actions that have been performed by the User, or arising from the non-compliance with any of its terms and conditions, or from non-compliance with applicable laws.</p>
<p><br></p>
<p>Intellectual Property</p>
<p>1. The User shall respect Bondai&rsquo;s intellectual property rights including, but not limited to: the Platform, words, logos, images, videos, sounds and icons belonging to Bondai or displayed on the Platform.</p>
<p>2. Bondai and each right thereof are rights protected by intellectual property and trademark laws, and are the property of Bondai, its affiliates and licensees and may not in any way be infringed or used without the authorization of Bondai.</p>
<p>3. In the event that any of Bondi&apos;s rights or intellectual property is infringed or violated, Bondai will take the necessary measures regarding these infringement.</p>
<p><br></p>
<p><br></p>
<p><strong>Legal Notices</strong></p>
<ul>
    <li>All legal notices required to be notified in accordance with Saudi regulations are sent through the means of communication provided to us by you, which includes phone calls, SMS, or via e-mail. These telephone or electronic notices replace all written notices required by law, and they are officially approved between parties.<br><br></li>
    <li>You acknowledge that you waive any legal rights to receive any written notifications from the Platform and acknowledges that the Platform is not obligated to provide written notifications and may replace them with telephone or electronic notifications in producing its legal actions, and that it is acknowledged by all the official authorities.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>Data Protection</strong></p>
<p>You acknowledge and agree that your use of the Platform and the information you provided to us which we use, and store is subject to our <a class='primary--text cursor-pointer' href="https://beta.bondai.io/privacy-policy"><strong>Privacy Policy</strong></a>.</p>
<p><br></p>
<p><strong>Cookies and Other Tracking Technologies</strong></p>
<p>We may use cookies and other technologies to facilitate and track your use of the Services offered on the Platform. For more information on that please see our <a class='primary--text cursor-pointer' href="https://beta.bondai.io/privacy-policy"><strong>Privacy Policy</strong></a>.</p>
<p><br></p>
<p>Applicable Law</p>
<p>These Terms are subject to all relevant laws and regulations in force in the Kingdom of Saudi Arabia.</p>
<p><br></p>
<p><br></p>
<p><strong>Complaints</strong></p>
<p>In the event that you encounter a problem or want to file a complaint, you can contact us through the customer service team, and in the event it appears that the complaint submitted is the concern of the Service Provider, you will be notified of this, and we will provide you with contact information of the Service Provider to submit your complaint to them directly. Bondai disclaims any responsibility for any complaint filed with the Service Provider because when you reserve a Service, you enter into an independent contractual relationship with the Service Provider, and you will be subject to the terms and conditions stipulated on the Service Provider&apos;s website.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong>General Terms</strong></p>
<ul>
    <li>The Platform is the only party competent to interpret or explain the provisions of these terms and conditions, so you must contact us immediately in the event that any of these terms are not clear to you.</li>
    <li>In the event that any provision of these Terms of Use becomes invalid, it does not revoke the validity of the rest of the provisions of the Terms of Use, and they remain in effect until further notice.<br><br></li>
    <li>The Platform reserves the right to change this usage agreement from time to time, and we will inform you about any updates or modifications to these Terms of Use by publishing the Terms of Use indicating the date of the last modification or update. Therefore, please check these Terms of Use periodically for any changes.<br><br></li>
    <li>If these terms are translated into any other language, the Arabic language will be the applicable language in all transactions.</li>
</ul>
<p><br></p>
<p><br></p>
<p><strong>Date of publication: 15/02/2023</strong></p>
    
    `,

    refundTitle: "Refund Policy",
    refundHereSentence: `Based on the applicable laws of your country, you may have the right to
request access to the personal information we collect from you, change
that information, or delete it in some circumstances. To request to
review, update, or delete your personal information, please submit a
request form by clicking`,
    here: "here",
    refunds: `
    <p><span style="font-size: 18px;"><strong>Refund Policy</strong></span></p>
    <p><br></p>
    <ul>
        <li>
            <p>It is possible to start a booking cancellation request by contacting Bondai Customer Service Team via the dedicated email address (hi@bondai.io), phone call (+966 55 507 4410) or our main Social Media Accounts (Instagram, WhatsApp, Twitter).&nbsp;</p>
            <ul>
                <li>
                    <p>It is important to note that the official cancellation request must be submitted via the Bondai booking portal from the account used to make the reservation.</p>
                </li>
            </ul>
        </li>
        <li>
            <p>As cancellation policies may vary between packages, please refer to the specific package&apos;s cancellation policy before making your reservation or starting a cancellation request.</p>
        </li>
        <li>
            <p>In the case that no cancellation policy was specified at the time of the booking, Private requests are non-refundable. And the below will be applicable for other experiences and trips:&nbsp;</p>
            <ul>
                <li>
                    <p>Fully refundable up to 7 days prior to the experience start date.</p>
                </li>
                <li>
                    <p>Subject to a cancellation fee of 100% of the total amount from less than 7 days prior to the experience departure date.</p>
                </li>
            </ul>
        </li>
        <li>
            <p>In the event of a Force Majeure situation, Bondai may modify, change or cancel the services booked and will contact the Customer as soon as possible to inform them about the changes made.&nbsp;</p>
        </li>
        <li>
            <p>Force Majeure Situations include but are not limited to overbookings, blackout dates, natural disasters, civil unrest, and any other unforeseeable events that may affect the booked services.</p>
            <ul>
                <li>
                    <p>These cases will be individually evaluated to understand the possibility of a date change or refund, please contact Bondai Customer Service to proceed with such requests and for any additional information.</p>
                </li>
            </ul>
        </li>
        <li>
            <p>Bondai does not assume any responsibility for the VISA services associated with packages. It is the Customer&apos;s responsibility to ensure that appropriate immigration documents are in order and up to date</p>
        </li>
    </ul>
`,

    privacyTitle: "Privacy Policy",
    privacyHereSentence: `    Based on the applicable laws of your country, you may have the right to
request access to the personal information we collect from you, change
that information, or delete it in some circumstances. To request to
review, update, or delete your personal information, please submit a
request form by clicking`,
    privacy: `
    
    <p><strong>Privacy Policy</strong></p>
<p><br></p>
<p>Bondai Company for Travel and Tourism (&ldquo;<strong>Bondai</strong>&rdquo;) believes that the protection of your personal data is an important matter and wants to inform you as a user of Bondai website/application <a data-fr-linked="true" href="//www.bondai.io">www.bondai.io</a> (The &ldquo;<strong>Platform</strong>&rdquo;) how we collect, use, process and disclose your personal information and how we apply confidentiality and privacy in Bondai. Therefore, Bondai has created this Privacy Policy to show its commitment to protecting the privacy of its users&apos; data on its Platform and to explain to you what information we collect while you use our Services (the &ldquo;<strong>Services</strong>&rdquo;).</p>
<p><br></p>
<p>Please read this Privacy Policy, as all your information resulting from accessing and using Bondai Platform will be subject to this policy.</p>
<p><br></p>
<p><br></p>
<p><strong>What Does the Privacy Policy Cover?</strong></p>
<p>This Privacy Policy covers information that Bondai obtains and maintains in its electronic systems, namely:</p>
<p>Information and personal data that you provide to us.</p>
<p>Information collected automatically when you use the Services on the Platform.</p>
<p>Personal information that is from external sources.</p>
<p><br></p>
<p><strong>Personal Information You Provide to Us</strong></p>
<p>Personal information is information of a personal nature that a customer provides when using the Services of the Platform and which enables Bondai to identify users on its Platform, including, but not limited to, the following data:</p>
<p><br></p>
<p><strong>Account Data</strong>: When you sign up for an account with us, we ask you for certain personal information to open your account, such as your name, number, email address, and password.</p>
<p><br></p>
<p><strong>Payment Data</strong>: We collect your payment details, bank account numbers, and transaction information which may be processed and stored by one or more of third parties providing payment services or payment gateway companies.</p>
<p><br></p>
<p><strong>Information Collected Automatically</strong></p>
<p>Non-personal information that your browser automatically sends to our servers. It is known as data logging and includes, but is not limited to, the following data:</p>
<p><br></p>
<p><strong>Usage Data</strong>: We collect information about your interaction with the Services, such as the pages or content you view on the App, the searches you perform, information about your activity and time spent on a page or screen, and navigation paths between pages.</p>
<p><br></p>
<p><strong>Location Data</strong>: We may collect information about your location, for example: the city/country associated with your IP address. Also, when you use the location sharing feature with Bondai Platform, we may collect the location information of your mobile device. Keep in mind that most mobile devices allow you to enable or disable the location services for any App on your mobile in the settings of the device.</p>
<p><br></p>
<p><strong>Device Data</strong>: We collect information about your computer or mobile device, such as the type of operating system, device model, manufacturer, browser type, screen resolution, device identifiers, IP address, or general location information such as city, country, or geographic area.</p>
<p><br></p>
<p><strong>Cookies and Similar Technologies</strong>: As is common with many other websites, cookies are sent to your device. Its purpose is to understand your interaction and use of the Services provided on the Platform, in order to measure the performance of users and improve the Services provided on the Platform.</p>
<p><br></p>
<p><strong>Communication</strong>: When you communicate or correspond with customer service, by phone, e-mail, or through the means of communication published on the Platform, we collect information related to contacts and any other information that you provide to us.</p>
<p><br></p>
<p><strong>Personal Information from External Sources:</strong></p>
<p>Personal information is obtained when you use third-party services, such as when you log in at Bondai Platform through a third-party website or application (for example, Google, Facebook, or Apple), which this party authorizes us to access your registration information and profile data. You, therefore, agree that Bondai may collect, store and use such information in accordance with this Privacy Policy. We are not responsible for how those third parties use and share your information. Thus, please refer to the privacy policies of those third parties to understand how they use and share your personal information.</p>
<p><br></p>
<p><br></p>
<p><strong>Why Do We Use Your Personal Information?</strong></p>
<p>We use your personal information to achieve various purposes, including but not limited to:</p>
<p><br></p>
<p><strong>Opening an Account</strong>: We use your personal data to create your account on the Platform, which enables you to use the Platform, process transactions and payments, make use of the offered Services, and respond to your inquiries.</p>
<p><br></p>
<p><strong>Customer Service</strong>: The customer service team needs to see your personal data and details in order to respond to your inquiries, provide the services and provide the assistance you need.</p>
<p><br></p>
<p><strong>Marketing Activities</strong>: We use your personal information for analytical or statistical purposes to achieve promotional, marketing and advertising purposes.</p>
<p><br></p>
<p><strong>Communicate With You</strong>: We use your personal information to verify your account and for any operational and advertising purposes such as managing your account or providing technical support to you, including sending you periodic messages about promotional offers in connection with the Service.</p>
<p><br></p>
<p><strong>Improving Our Services</strong>: We also use your personal data for analytics purposes and to improve our Services and how they are delivered.</p>
<p><br></p>
<p><strong>Legal Purposes</strong>: We may need to use your personal information to deal with and resolve legal claims and disputes, to enforce our terms and conditions or to comply with legal requests from competent authorities.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong><br></strong></p>
<p><strong>Who are the Third Parties with Whom We Share Your Personal Data?</strong></p>
<p><br></p>
<p><strong>Service Providers</strong>: We may use external service providers to support us in providing our Services, including but not limited to, the following service providers:</p>
<p><br></p>
<p>Websites hosting companies and applications to store content, platform and data.</p>
<p>Call centre and Customer Relationship Management (CRM).</p>
<p>Payment gateways.</p>
<p><br></p>
<p><strong>Responding to Legal Requests</strong>: We may disclose your personal information to courts, governmental or tax authorities, or other relevant third parties to the extent permitted by applicable law.</p>
<p><br></p>
<p><strong>Business Partners</strong>: We may disclose your personal information with our business partners to the extent of providing you the requested Services you choose on the Platform.</p>
<p><br></p>
<p><strong>Other than that, and unless we have your consent, we will not share your personal information with any other third parties in any way.</strong></p>
<p><br></p>
<p><br></p>
<p><strong>Information Security</strong></p>
<p>We make every effort to take all preventive measures and apply all technical, physical and organizational measures in order to protect your information from any unauthorized access while transferring or storing any of your personal data. However, we cannot guarantee 100% security when transferring, storing or protecting information because we cannot guarantee the security of the Internet due to the possibility of breaches in the protection systems.</p>
<p><br></p>
<p><strong>Data Transfers</strong></p>
<p>We may transfer to, and store the data we collect about you in, countries other than the country in which the data was originally collected, including the United States, Canada or other destinations outside Saudi Arabia, the European Economic Area (&ldquo;EEA&rdquo;) or the United Kingdom. These countries may not have the same data protection laws as the country in which you provided the data. When we transfer your data to other countries, we will protect the data as described in this Privacy Policy and comply with applicable legal requirements providing adequate protection for the transfer of data to countries outside Saudi Arabia, the EEA or the United Kingdom.</p>
<p><br></p>
<p>If you are located in Saudi Arabia, the EEA or the United Kingdom, we will only transfer your personal data if we have put in place appropriate safeguards in respect of the transfer that is accepted by the laws of Saudi Arabia, the EEA, or the United Kingdom, and approved by the competent authorities in these countries.</p>
<p><br></p>
<p><br></p>
<p><strong>How Long We Keep Personal Data For?</strong></p>
<p>We only retain your personal data for as long as needed to fulfil the purposes for which it is collected, unless we are required or permitted by law to keep the personal data for longer.</p>
<p><br></p>
<p><br></p>
<p><strong>Changes to the Privacy Policy </strong></p>
<p>Bondai reserves the right to change this Privacy Policy from time to time, and we will notify you of any updates or modifications to this Privacy Policy by posting the new Privacy Policy indicating the date of the last modification or update. Therefore, please review this Privacy Policy periodically for any changes.</p>
<p><br></p>
<p><strong>Amending and Updating Your Personal Data</strong></p>
<p>You can review, correct, update and edit certain information you previously provided to us at any time by logging into your account and reviewing your account and profile settings. You may also access or request correction of your information by contacting us, and we may need to verify your identity before carrying out your request.</p>
<p><br></p>
<p><strong>Approval</strong></p>
<p>By reading this Privacy Policy, you agree to the collection, use and disclosure of your personal information as described in this Policy, and if you do not agree to this, please do not provide us with any of your personal information and discontinue the use of our Services immediately.</p>
<p><br></p>
<p><strong>Date of Publication: 15/02/2023</strong></p>
    `,

    cookiesTitle: "Cookies Policy",
    cookiesHereSentence: ` Based on the applicable laws of your country, you may have the right to
request access to the personal information we collect from you, change
that information, or delete it in some circumstances. To request to
review, update, or delete your personal information, please submit a
request form by clicking `,
    cookies: `

<div>
    <p>
        Last updated September 11, 2019
    </p>
    <p>
        <br/>
        Thank you for choosing to be part of our community at Bondai inc.
        ("Company", "we", "us", "our"). We are committed to protecting your
        personal information and your right to privacy. If you have any
        questions or concerns about this privacy notice, or our practices with
regards to your personal information, please contact us at        <a href="mailto:hi@bondai.io">hi@bondai.io</a>.
    </p>
    <p>
        <br/>
When you visit our website        <a href="https://www.bondai.io/">https://www.bondai.io</a> (the
        "Website"), and more generally, use any of our services (the
        "Services", which include the Website), we appreciate that you are
        trusting us with your personal information. We take your privacy very
        seriously. In this privacy notice, we seek to explain to you in the
        clearest way possible what information we collect, how we use it and
        what rights you have in relation to it. We hope you take some time to
        read through it carefully, as it is important. If there are any terms
        in this privacy notice that you do not agree with, please discontinue
        use of our Services immediately.
    </p>
    <p>
        <br/>
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our Website), as well as,
        any related services, sales, marketing or events.
    </p>
    <p>
        <br/>
        Please read this privacy notice carefully as it will help you
        understand what we do with the information that we collect.
    </p>
    <p>
        <br/>
        TABLE OF CONTENTS
    </p>
    <p>
        <br/>
        1. WHAT INFORMATION DO WE COLLECT?
    </p>
    <p>
        <br/>
        2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    </p>
    <p>
        <br/>
        3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    </p>
    <p>
        <br/>
        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
    </p>
    <p>
        <br/>
        5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
    </p>
    <p>
        <br/>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
    </p>
    <p>
        <br/>
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
    </p>
    <p>
        <br/>
        8. DO WE COLLECT INFORMATION FROM MINORS?
    </p>
    <p>
        <br/>
        9. WHAT ARE YOUR PRIVACY RIGHTS?
    </p>
    <p>
        <br/>
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
    </p>
    <p>
        <br/>
        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </p>
    <p>
        <br/>
        12. DO WE MAKE UPDATES TO THIS NOTICE?
    </p>
    <p>
        <br/>
        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </p>
    <p>
        <br/>
        14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
    </p>
    <p>
        <br/>
        1. WHAT INFORMATION DO WE COLLECT?
    </p>
    <p>
        Personal information you disclose to us
    </p>
    <p>
        In Short: We collect personal information that you provide to us.
    </p>
    <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Website, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Website (such as by posting messages in our online
        forums or entering competitions, contests or giveaways) or otherwise
        when you contact us.
    </p>
    <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Website, the choices you make and the
        products and features you use. The personal information we collect may
        include the following:
    </p>
    <p>
        Payment Data. We may collect data necessary to process your payment if
        you make purchases, such as your payment instrument number (such as a
        credit card number), and the security code associated with your payment
        instrument. All payment data is stored by PayBilla . You may find their
        privacy notice link(s) here: __________.
    </p>
    <p>
        Social Media Login Data. We may provide you with the option to register
        with us using your existing social media account details, like your
        Facebook, Twitter or other social media account. If you choose to
        register in this way, we will collect the information described in the
        section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
    </p>
    <p>
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
    </p>
    <p>
        <br/>
        Information automatically collected
    </p>
    <p>
        In Short: Some information — such as your Internet Protocol (IP)
        address and/or browser and device characteristics — is collected
        automatically when you visit our Website.
    </p>
    <p>
        We automatically collect certain information when you visit, use or
        navigate the Website. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring
        URLs, device name, country, location, information about how and when
        you use our Website and other technical information. This information
        is primarily needed to maintain the security and operation of our
        Website, and for our internal analytics and reporting purposes.
    </p>
    <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
    </p>
    <p>
        <br/>
        Information collected from other sources
    </p>
    <p>
        In Short: We may collect limited data from public databases, marketing
        partners, social media platforms, and other outside sources.
    </p>
    <p>
        In order to enhance our ability to provide relevant marketing, offers
        and services to you and update our records, we may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, affiliate programs, data providers, social media platforms,
        as well as from other third parties. This information includes mailing
        addresses, job titles, email addresses, phone numbers, intent data (or
        user behavior data), Internet Protocol (IP) addresses, social media
        profiles, social media URLs and custom profiles, for purposes of
        targeted advertising and event promotion. If you interact with us on a
        social media platform using your social media account (e.g. Facebook or
        Twitter), we receive personal information about you such as your name,
        email address, and gender. Any personal information that we collect
        from your social media account depends on your social media account's
        privacy settings.
    </p>
    <p>
        <br/>
        2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    </p>
    <p>
        In Short: We only share information with your consent, to comply with
        laws, to provide you with services, to protect your rights, or to
        fulfill business obligations.
    </p>
    <p>
        We may process or share your data that we hold based on the following
        legal basis:
    </p>
    <p>
        Consent: We may process your data if you have given us specific consent
        to use your personal information for a specific purpose.
    </p>
    <p>
        Legitimate Interests: We may process your data when it is reasonably
        necessary to achieve our legitimate business interests.
    </p>
    <p>
        Performance of a Contract: Where we have entered into a contract with
        you, we may process your personal information to fulfill the terms of
        our contract.
    </p>
    <p>
        Legal Obligations: We may disclose your information where we are
        legally required to do so in order to comply with applicable law,
        governmental requests, a judicial proceeding, court order, or legal
        process, such as in response to a court order or a subpoena (including
        in response to public authorities to meet national security or law
        enforcement requirements).
    </p>
    <p>
        Vital Interests: We may disclose your information where we believe it
        is necessary to investigate, prevent, or take action regarding
        potential violations of our policies, suspected fraud, situations
        involving potential threats to the safety of any person and illegal
        activities, or as evidence in litigation in which we are involved.
    </p>
    <p>
        More specifically, we may need to process your data or share your
        personal information in the following situations:
    </p>
    <p>
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business
        to another company.
    </p>
    <p>
        Google Maps Platform APIs. We may share your information with certain
        Google Maps Platform APIs (e.g., Google Maps API, Place API). To find
        out more about Google's Privacy Policy, please refer to this link. We
        use certain Google Maps Platform APIs to retrieve certain information
        when you make location-specific requests. This includes: and other
        similar information. A full list of what we use information for can be
        found in this section. We obtain and store on your device ('cache')
        your location for __________ months. You may revoke your consent
        anytime by contacting us at the contact details provided at the end of
        this document. The Google Maps Platform APIs that we use store and
        access cookies and other information on your devices. If you are a user
        currently in the European Economic Area (EU countries, Iceland,
        Liechtenstein and Norway) or United Kingdom, please take a look at our
        Cookie Notice.
    </p>
    <p>
        Affiliates. We may share your information with our affiliates, in which
        case we will require those affiliates to honor this privacy notice.
        Affiliates include our parent company and any subsidiaries, joint
        venture partners or other companies that we control or that are under
        common control with us.
    </p>
    <p>
        Business Partners. We may share your information with our business
        partners to offer you certain products, services or promotions.
    </p>
    <p>
        Other Users. When you share personal information (for example, by
        posting comments, contributions or other content to the Website) or
        otherwise interact with public areas of the Website, such personal
        information may be viewed by all users and may be publicly made
        available outside the Website in perpetuity. If you interact with other
        users of our Website and register for our Website through a social
        network (such as Facebook), your contacts on the social network will
        see your name, profile photo, and descriptions of your activity.
        Similarly, other users will be able to view descriptions of your
        activity, communicate with you within our Website, and view your
        profile.
    </p>
    <p>
        <br/>
        3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    </p>
    <p>
        In Short: We may use cookies and other tracking technologies to collect
        and store your information.
    </p>
    <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
    </p>
    <p>
        <br/>
        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
    </p>
    <p>
        In Short: If you choose to register or log in to our services using a
        social media account, we may have access to certain information about
        you.
    </p>
    <p>
        Our Website offers you the ability to register and login using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, profile picture as well as other information you choose to make
        public on such social media platform.
    </p>
    <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to
        you on the relevant Website. Please note that we do not control, and
        are not responsible for, other uses of your personal information by
        your third-party social media provider. We recommend that you review
        their privacy notice to understand how they collect, use and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
    </p>
    <p>
        <br/>
        5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
    </p>
    <p>
        In Short: We may transfer, store, and process your information in
        countries other than your own.
    </p>
    <p>
        Our servers are located in. If you are accessing our Website from
        outside, please be aware that your information may be transferred to,
        stored, and processed by us in our facilities and by those third
        parties with whom we may share your personal information (see "WILL
        YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other
        countries.
    </p>
    <p>
        If you are a resident in the European Economic Area (EEA) or United
        Kingdom (UK), then these countries may not necessarily have data
        protection laws or other similar laws as comprehensive as those in your
        country. We will however take all necessary measures to protect your
        personal information in accordance with this privacy notice and
        applicable law.
    </p>
    <p>
        <br/>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
    </p>
    <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
    </p>
    <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than .
    </p>
    <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any
        further processing until deletion is possible.
    </p>
    <p>
        <br/>
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
    </p>
    <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
    </p>
    <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information
        we process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security, and
        improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission
        of personal information to and from our Website is at your own risk.
        You should only access the Website within a secure environment.
    </p>
    <p>
        <br/>
        8. DO WE COLLECT INFORMATION FROM MINORS?
    </p>
    <p>
        In Short: We do not knowingly collect data from or market to children
        under 18 years of age.
    </p>
    <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Website, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent
        to such minor dependent's use of the Website. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
may have collected from children under age 18, please contact us at        <a href="mailto:hi@bondai.io">hi@bondai.io</a>.
    </p>
    <p>
        <br/>
        9. WHAT ARE YOUR PRIVACY RIGHTS?
    </p>
    <p>
        In Short: In some regions, such as the European Economic Area (EEA) and
        United Kingdom (UK), you have rights that allow you greater access to
        and control over your personal information. You may review, change, or
        terminate your account at any time.
    </p>
    <p>
        In some regions (like the EEA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the contact details provided below. We will consider and act upon
        any request in accordance with applicable data protection laws.
    </p>
    <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. Please note
        however that this will not affect the lawfulness of the processing
        before its withdrawal, nor will it affect the processing of your
        personal information conducted in reliance on lawful processing grounds
        other than consent.
    </p>
    <p>
        If you are a resident in the EEA or UK and you believe we are
        unlawfully processing your personal information, you also have the
        right to complain to your local data protection supervisory authority.
        You can find their contact details here:
        <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
    </p>
    <p>
        If you are a resident in Switzerland, the contact details for the data
        protection authorities are available here:
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            https://www.edoeb.admin.ch/edoeb/en/home.html
        </a>
        .
    </p>
    <p>
        <br/>
        Account Information
    </p>
    <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
    </p>
    <p>
        Log into your account settings and update your user account.
    </p>
    <p>
        Contact us using the contact information provided.
    </p>
    <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our
        Terms of Use and/or comply with applicable legal requirements.
    </p>
    <p>
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to
        remove cookies or reject cookies, this could affect certain features or
        services of our Website. To opt-out of interest-based advertising by
        advertisers on our Website visit
        <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/
        </a>
        .
    </p>
    <p>
        Opting out of email marketing: You can unsubscribe from our marketing
        email list at any time by clicking on the unsubscribe link in the
        emails that we send or by contacting us using the details provided
        below. You will then be removed from the marketing email list —
        however, we may still communicate with you, for example to send you
        service-related emails that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes. To otherwise opt-out, you may:
    </p>
    <p>
        Access your account settings and update preferences.
    </p>
    <p>
        <br/>
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
    </p>
    <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT
        signals has been finalized. As such, we do not currently respond to DNT
        browser signals or any other mechanism that automatically communicates
        your choice not to be tracked online. If a standard for online tracking
        is adopted that we must follow in the future, we will inform you about
        that practice in a revised version of this privacy notice.
    </p>
    <p>
        <br/>
        11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </p>
    <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
    </p>
    <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of
        all third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
    </p>
    <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Website, you have the right to request
        removal of unwanted data that you publicly post on the Website. To
        request removal of such data, please contact us using the contact
        information provided below, and include the email address associated
        with your account and a statement that you reside in California. We
        will make sure the data is not publicly displayed on the Website, but
        please be aware that the data may not be completely or comprehensively
        removed from all our systems (e.g. backups, etc.).
    </p>
    <p>
        <br/>
        12. DO WE MAKE UPDATES TO THIS NOTICE?
    </p>
    <p>
        In Short: Yes, we will update this notice as necessary to stay
        compliant with relevant laws.
    </p>
    <p>
        We may update this privacy notice from time to time. The updated
        version will be indicated by an updated "Revised" date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you
        a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
    </p>
    <p>
        <br/>
        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </p>
    <p>
        If you have questions or comments about this notice, you may email us
        at <a href="mailto:hi@bondai.io">hi@bondai.io</a> or by post to:
    </p>
    <p>
        Bondai inc.
    </p>
    <p>
        2035 Sunset Lake RD STE B2
    </p>
    <p>
        Newark , DE 19702-2600
    </p>
    <p>
        United States
    </p>
    <p>
        <br/>
        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </p>
</div>
`,
  },
};
