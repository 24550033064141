import Vue from "vue";
import Vuetify, {
  VTextField,
  VSelect,
  VTextarea,
  VCheckbox,
  VApp,
  VIcon,
  VTooltip,
  VRating,
  VOtpInput,
  VDialog,
  VDivider,
  VBtn,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VBreadcrumbs,
  VDataTable,
  VTab,
  VTabs,
  VListItemContent,
  VPagination,
  VRadio,
  VRadioGroup,
  VListItem,
  VListGroup,
  VListItemGroup,
  VAppBarNavIcon,
  VListItemTitle,
  VMenu,
  VDatePicker,
  VImg,
  VProgressCircular,
  VSkeletonLoader,
  VSwitch,
  VRangeSlider,
  VForm,
  VAvatar,
  VSnackbar,
  VList,
  VAppBar,
  VAutocomplete,
  VRow,
  VCol,
  VExpansionPanels,
  VExpansionPanel,
  VNavigationDrawer,
} from "vuetify/lib";
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  components: {
    VTextField,
    VExpansionPanels,
    VSelect,
    VTextarea,
    VExpansionPanel,
    VCheckbox,
    VApp,
    VDivider,
    VIcon,
    VTooltip,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VBreadcrumbs,
    VOtpInput,
    VListItemContent,
    VListItem,
    VListItemTitle,
    VRating,
    VDialog,
    VBtn,
    VDataTable,
    VMenu,
    VDatePicker,
    VImg,
    VProgressCircular,
    VSkeletonLoader,
    VSwitch,
    VListGroup,
    VListItemGroup,
    VRadio,
    VRadioGroup,
    VAppBarNavIcon,
    VRangeSlider,
    VPagination,
    VTab,
    VAutocomplete,
    VTabs,
    VForm,
    VAvatar,
    VSnackbar,
    VList,
    VAppBar,
    VRow,
    VCol,
    VNavigationDrawer,
  },
});
const opts = {
  icons: {
    iconfont: "mdiSvg",
  },
  breakpoint: {
    scrollBarWidth: 24,
  },
  rtl: localStorage.getItem("language") == "ar" ? true : false,
  theme: {
    disable: true,
  },
};

export default new Vuetify(opts);
