<template>
  <div>
    <!-- langeuages desktop-->
    <v-menu
      v-if="!isMd"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center mx-1" v-bind="attrs" v-on="on">
          <img
            class="mt-1"
            width="18px"
            height="14px"
            v-if="selectedLanguage == 'arabic'"
            :src="require('@/assets/images/global/ar-lang.svg')"
            alt=""
          />
          <img
            class="mt-1"
            width="18px"
            height="14px"
            v-if="selectedLanguage == 'english'"
            :src="require('@/assets/images/global/en-lang.svg')"
            alt=""
          />
          <img
            class="mt-1"
            width="18px"
            height="14px"
            v-if="selectedLanguage == 'chinese'"
            :src="require('@/assets/images/global/zh-lang.png')"
            alt=""
          />
          <span
            class="font-14 d-inline-block mx-1 language-title"
            :class="isHomepage ? ' white--text' : ' darkblue2--text'"
            >{{ $t(`global.${selectedLanguage}`) }}</span
          >
          <img
            class="transition-03 mt-1"
            width="12px"
            height="12px"
            :src="
              require(`@/assets/images/icons/arrow-down${
                isHomepage ? '-white' : ''
              }.svg`)
            "
          />
        </div>
      </template>

      <v-list :elevation="0">
        <v-list-item
          v-for="(lang, i) in languages"
          :key="i"
          @click="$emit('switchLanguage', lang)"
          class="text-center"
        >
          <img class="mx-1" width="18px" height="14px" :src="lang.img" />
          <v-list-item-title
            class="cursor-pointer font-14"
            :class="$t(`global.${lang.title}`)"
            >{{ $t(`global.${lang.title}`) }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- languages mobile -->
    <v-list-group v-if="isMd" no-action class="px-0">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title >
            <div class="d-flex align-center">
              <img
                class="mt-1"
                width="18px"
                height="14px"
                v-if="selectedLanguage == 'arabic'"
                :src="require('@/assets/images/global/ar-lang.svg')"
              />
              <img
                class="mt-1"
                width="18px"
                height="14px"
                v-if="selectedLanguage == 'english'"
                :src="require('@/assets/images/global/en-lang.svg')"
              />
              <img
                class="mt-1"
                width="18px"
                height="14px"
                v-if="selectedLanguage == 'chinese'"
                :src="require('@/assets/images/global/zh-lang.png')"
              />
              <span
                class="black--text font-14 d-inline-block pb-1 mx-2 pt-2"
                :class="$t(`global.${selectedLanguage}`)"
                >{{ $t(`global.${selectedLanguage}`) }}</span
              >
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(lang, i) in languages"
        :key="i"
        @click="$emit('switchLanguage', lang)"
        class="px-5"
      >
        <img
          class="mt-1"
          :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
          width="16px"
          height="14px"
          :src="lang.img"
        />
        <v-list-item-title
          class="cursor-pointer px-0"
          :class="$t(`global.${lang.title}`)"
          >{{ $t(`global.${lang.title}`) }}</v-list-item-title
        >
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    selectedLanguage: {
      type: String,
      default: "",
    },
    lang: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["translate"]),
    isHomepage() {
      if (this.$route.name == "Homepage") {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    languages: [
      {
        title: "english",
        value: "en",
        img: require("@/assets/images/global/en-lang.svg"),
      },
      {
        title: "arabic",
        value: "ar",
        img: require("@/assets/images/global/ar-lang.svg"),
      },
      // {
      //   title: "chinese",
      //   value: "zh",
      //   img: require("@/assets/images/global/zh-lang.png"),
      // },
    ],
  }),
};
</script>

<style lang="scss">
.v-list-item {
  min-height: 40px !important;
  height: 40px !important;
  i{
    &::before{
      display: none
    }
  }
}
.v-list-item__title{
  line-height: 20px !important;
}
.language-title {
  line-height: normal !important;
}
</style>
