<template>
  <div :class="colorHandler(color)" >
    <v-snackbar v-model="show" :timeout="10000" top>
      <div
        v-if="typeToast == 'loginError'"
      >
        <span class="white--text">{{ translate["book.needLogin"] }} </span>
      </div>
      <div  else>
        {{ text }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
      typeToast: "",
    };
  },
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {
    colorHandler(color) {
      if (color == "error") return "errorText";
      if (color == "success") return "greenText";
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SHOW_MESSAGE") {
        this.text = state.text;
        this.color = state.color;
        this.typeToast = state.typeToast;
        this.timeout = state.timeout;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss" >
.greenText{

  .v-snack__wrapper{
    background: $greenText !important
  }
}
.errorText{

  .v-snack__wrapper{
    background: $errorText !important
  }
}
</style>
