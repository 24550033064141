var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_c('div',{staticClass:"transition-03 navbar--scrolled",class:_vm.isHomepage ? '' : 'navbar--white'}),(_vm.isMd)?_c('drawer-app',{attrs:{"links":_vm.links}}):_vm._e(),(!_vm.isMd)?_c('div',{staticClass:"navbar__max-width mt-4 mb-2 hidden-sm-and-down z-9 d-flex align-center justify-space-between"},[(!_vm.$vuetify.rtl)?_c('div',{staticClass:"d-flex align-center h-100 navbar__logo"},[_c('router-link',{attrs:{"to":'/' + _vm.$route.params.lang}},[(_vm.isHomepage)?_c('img',{staticClass:"mt-3",attrs:{"width":"100px","height":"35px","src":require(`@/assets/images/global/logo-white.svg`),"alt":"bondai logo"}}):_vm._e(),(!_vm.isHomepage)?_c('img',{staticClass:"mt-3",attrs:{"width":"100px","height":"35px","src":require(`@/assets/images/global/logo-second.svg`),"alt":"bondai logo"}}):_vm._e()])],1):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"w-100 d-flex align-center justify-end"},[_c('bondai-groups'),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"navbar__links py-3 d-flex align-center justify-center"},[_vm._l((_vm.links),function(link,i){return [(link.isRoute)?_c('router-link',{key:i,staticClass:"d-flex align-center navbar__links__link cursor-pointer mx-3",class:link.active ? 'navbar__links__link--active' : '',attrs:{"to":link.route}},[_c('span',{staticClass:"font-14",class:[
                    _vm.translate[link.title],
                    _vm.isHomepage ? ' white--text' : ' darkblue2--text',
                  ]},[_vm._v(_vm._s(_vm.translate[link.title]))])]):_vm._e(),(!link.isRoute)?_c('div',{key:i,staticClass:"d-flex align-center navbar__links__link cursor-pointer mx-3",class:link.active ? 'navbar__links__link--active' : '',on:{"click":function($event){return _vm.linkHandler(link)}}},[_c('span',{staticClass:"font-14",class:[
                    _vm.translate[link.title],
                    _vm.isHomepage ? ' white--text' : ' darkblue2--text',
                  ]},[_vm._v(_vm._s(_vm.translate[link.title]))])]):_vm._e()]})],2)]),_c('currencies'),_c('languages',{attrs:{"selectedLanguage":_vm.selectedLanguage},on:{"switchLanguage":_vm.switchLanguage}}),(!_vm.token)?_c('div',{staticClass:"d-flex align-center mx-2 cursor-pointer",class:_vm.isHomepage ? 'white-outline' : 'primary-outline',on:{"click":_vm.loginHandler}},[_c('span',{staticClass:"font-14 d-inline-block",class:_vm.isHomepage ? 'white--text' : 'primary--text'},[_vm._v(_vm._s(_vm.translate["form.login"]))])]):_vm._e(),(_vm.token)?_c('user-menu',{staticClass:"d-flex align-center cursor-pointer",on:{"loggedOut":_vm.logoutHandler}}):_vm._e(),(_vm.$vuetify.rtl)?_c('div',{staticClass:"navbar__logo"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.isHomepage)?_c('img',{staticClass:"mt-3",attrs:{"width":"100px","src":require('@/assets/images/global/logo-white.svg')}}):_vm._e(),(!_vm.isHomepage)?_c('img',{staticClass:"mt-3",attrs:{"width":"100px","src":require(`@/assets/images/global/logo-second.svg`),"alt":"bondai logo"}}):_vm._e()])],1):_vm._e()],1)])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"1100px","height":"600px","content-class":"radius-25 form-height white"},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[(_vm.type == 'login')?_c('login-form',{on:{"reset":function($event){_vm.type = 'resetPassword'},"close":function($event){_vm.loginDialog = false}}}):_vm._e(),(_vm.type == 'resetPassword')?_c('reset-email-password',{on:{"close":function($event){_vm.loginDialog = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }