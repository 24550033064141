<template>
  <div :class="rounded ? '' : 'search-style'">
    <v-text-field
      :value="value"
      @input="$emit('input', $event)"
      @keydown="$emit('keydown', $event)"
      :placeholder="searchPlaceholder || translate['global.search']"
      :background-color="background"
      :color="color"
      class="mt-1 radius-5"
      outlined
      :rounded="rounded"
      :hide-details="true"
      dense
      :rules="[]"
      height="50px"
      filled
      :class="rounded ? 'radius-100': ''"
    >
      <template v-slot:append v-if="!leftIcon">
        <div class="d-flex align-center mt-1">
          <img
            :src="
              require(`@/assets/images/icons/search${
                isPrimary ? '' : '-black'
              }.svg`)
            "
            v-if="!loadingSearch"
          />
          <div v-if="loadingSearch">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </div>
      </template>
      <template v-slot:prepend-inner v-if="leftIcon">
        <div class="d-flex align-center mt-1 mr-2">
          <!-- <img
            :src="require(`@/assets/images/icons/search${isPriamry ? '': '-black'}.svg`)"
            alt=""
          /> -->
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "#fff",
    },
    color: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["translate"]),
  },
};
</script>

<style lang="scss">
.error--text {
  .v-input__slot {
    fieldset {
      border: 1px solid #e44358 !important;
    }
  }
}
</style>
