import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
// helper
import helper from "./utils/helper";
import '@mdi/font/css/materialdesignicons.css'

import SsrCarousel from "vue-ssr-carousel";
import "vue-ssr-carousel/index.css";
import "./assets/style/rtl.scss";

import BaseButton from "./components/base/BaseButton";
import BaseInput from "./components/base/Input";
import BaseDate from "./components/base/Date";
import BaseSearch from "./components/base/Search";
import BaseSelect from "./components/base/Select";
import BaseTextarea from "./components/base/Textarea";
import LoadingSpinner from "@/components/global/Spinner";

// google
import * as VueGoogleMaps from "vue2-google-maps";

// i18n
import i18n from "./i18n";
import axios from "./plugins/axios";

// meta
import VueMeta from "vue-meta";

// directives
import LazyLoadDirective from "./directives/LazyLoad";

Vue.prototype.$helper = helper;

Vue.component("base-button", BaseButton);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-search", BaseSearch);
Vue.component("base-select", BaseSelect);
Vue.component("base-textarea", BaseTextarea);
Vue.component("loading-spinner", LoadingSpinner);
Vue.component("ssr-carousel", SsrCarousel);

Vue.directive("lazyload", LazyLoadDirective);

Vue.use(axios);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCLT7PMtBoSSjp9VDI0RTmQy7vi9IHzXMA",
    libraries: "places", // This is required if you use the Autocomplete plugin
    installComponents: true,
  },
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;
Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    isSm() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg
      );
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
