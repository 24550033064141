import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const isLogin = store.getters.token;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLogin) {
      next();
    } else {
      next({
        path: "/" + to.params.lang,
      });
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  let ourLangs = ["en", "ar"];
  let ourPages = [
    "explore",
    "blogs",
    "experiences",
    "payment-gateway",
    "booking-summary",
    "overview",
    "bookings",
    "profile",
    "dashboard",
    "favorites",
    "custom-blogs/:slug",
    "about-us",
    "create-your-trip",
    "adventure-complete",
    "categories",
    "refund-policy",
    "terms-policy",
    "cookies-policy",
    "privacy-policy",
    "faq",
    "contact-us",
    "header",
    "footer",
    "500",
    "explore",
    "calendar-result",
    "review",
    "register",
    "reset-password",
    "confirm-email-address",
  ];

  // check how many / in the route
  let slashConutInRoute = to.fullPath.replace('?', '/').split("/");
  let lang = localStorage.getItem("language") || "en";

  // 2 status to check
  // 1. if the page is wrong
  // 2. if the language is wrong
  if (
    slashConutInRoute.length > 2 &&
    !ourPages.includes(slashConutInRoute[2].toLowerCase())
  ) {
    if (slashConutInRoute[1] == 'reset-password' ||
      slashConutInRoute[1] == 'confirm-email-address' ||
      slashConutInRoute[1] == 'experiences' ||
      slashConutInRoute[1] == 'custom-blogs' ||
      slashConutInRoute[1] == 'blogs'
    ) {
      router.replace("/" + lang + '/' + slashConutInRoute[1] + '/' + slashConutInRoute[2]);
    } else {
      router.replace("/" + lang);
    }

  } else if (!ourLangs.includes(slashConutInRoute[1])) {
    if (ourPages.includes(slashConutInRoute[1])) {
      router.replace("/" + lang + "/" + slashConutInRoute[1]);
    } else {
      router.replace("/" + lang);
    }
  } else if (!to.params.lang) {
    router.replace("/" + localStorage.getItem("language"));
  }
});

export default router;
