import Vue from "vue";
import VueI18n from "vue-i18n";
import AR from "./ar";
import EN from "./en";
Vue.use(VueI18n);

export default new VueI18n({
  silentFallbackWarn: true,
  lazy:true,
  locale: localStorage.getItem("language") || "ar",
  fallbackLocale: "ar",
  messages: {
    en: EN,
    ar: AR,
  },
});
