<template>
  <div class="base-date">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="input-style form.selectDate"
          outlined
          :label="label ? label : translate['form.selectDate']"
          dense
          :hide-details="true"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          :rules="rules"
          v-on="on"
          :value="
            isString ? value : date.length > 2 ? '+3 dates selected ' : value
          "
          v-if="!range"
          @input="$emit('input', $event)"
        ></v-text-field>
        <v-text-field
          class="input-style form.selectDate"
          outlined
          :label="label ? label : translate['form.selectDate']"
          dense
          :hide-details="true"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          :rules="rules"
          v-on="on"
          :value="
            value.length > 0
              ? value.length == 1
                ? value[0]
                : value[0] + ' ~ ' + value[1]
              : ''
          "
          v-if="range"
          @input="$emit('input', $event)"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        :locale="language"
        scrollable
        :multiple="multiple"
        :range="range"
        :min="min ? min || new Date().toISOString().substr(0, 10) : ''"
        :max="max ? max : ''"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="okHandler"> OK </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isString: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String,Boolean],
      default: true,
    },
    max: {
      type: [Boolean,String],
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
  },
  data: () => ({
    date: null,
    menu: false,
  }),
  computed: {
    ...mapGetters(["translate"]),
    language(){
      if(localStorage.getItem('language') == 'ar'){
        return 'ar'
      }else{
        return 'en'
      }
    }
  },
  methods: {
    okHandler() {
      this.menu = false;
      this.$emit("valueHandler", this.date);
    },
  },
  created() {
    this.date = this.value;
  },
};
</script>

<style lang="scss">
.base-date {
  .v-input__icon {
    margin-top: 6px !important;
  }
}
</style>
